import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between w-100" }
const _hoisted_2 = { class: "d-flex ga-2" }
const _hoisted_3 = { class: "tax-input" }
const _hoisted_4 = { class: "d-flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PriceCalculator = _resolveComponent("PriceCalculator")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_NumberField = _resolveComponent("NumberField")!
  const _component_CardTitle = _resolveComponent("CardTitle")!
  const _component_VirtualDesktopAddon = _resolveComponent("VirtualDesktopAddon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_CompleteSetup = _resolveComponent("CompleteSetup")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { class: "hidden-md-and-up price-calculator-mobile" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_PriceCalculator, {
              feePerHour: _ctx.getVmPerHourPrice,
              currency: _ctx.selectedCurrency
            }, null, 8, ["feePerHour", "currency"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_PageHeader, {
      title: "Create Your Smart Workbench",
      subTitle: "Its easy to customise and set up a new virtual desktop. Your new virtual desktop can be assigned to your team member anywhere in the world"
    }),
    _createVNode(_component_v_form, {
      ref: "frmVD",
      modelValue: _ctx.vdValid,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.vdValid) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          flat: "",
          class: "mt-2",
          loading: _ctx.loading,
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardTitle, { title: "Virtual Desktop Configuration" }, {
              "sub-title": _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _cache[14] || (_cache[14] = _createElementVNode("div", { class: "d-flex flex-column" }, [
                    _createElementVNode("span", null, "Create a new Virtual Desktop, choose your configuration."),
                    _createElementVNode("a", null, [
                      _createElementVNode("span", null, "What is the right Virtual Desktop for me?")
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_SelectField, {
                      rules: [_ctx.rules.required],
                      items: _ctx.currencies,
                      modelValue: _ctx.selectedCurrency,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCurrency) = $event)),
                      label: "Currency",
                      placeholder: "Currency"
                    }, null, 8, ["rules", "items", "modelValue"]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_NumberField, {
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.selectedTax,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTax) = $event)),
                        min: "0",
                        label: "Tax"
                      }, null, 8, ["rules", "modelValue"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_VirtualDesktopAddon, {
                          virtualDesktopSpec: _ctx.model.Addons?.VirtualDesktopAddon,
                          diskValues: _ctx.diskValues,
                          cpuValues: _ctx.cpuValues,
                          ramValues: _ctx.ramValues,
                          isAddon: false,
                          fullScreen: true
                        }, null, 8, ["virtualDesktopSpec", "diskValues", "cpuValues", "ramValues"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "hidden-sm-and-down"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_PriceCalculator, {
                            class: "price-calculator-desktop",
                            feePerHour: _ctx.getVmPerHourPrice,
                            currency: _ctx.selectedCurrency
                          }, null, 8, ["feePerHour", "currency"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading", "disabled"]),
        _createVNode(_component_v_card, {
          flat: "",
          class: "mt-2",
          loading: _ctx.loading,
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardTitle, { title: "Team Member" }, {
              "sub-title": _withCtx(() => _cache[15] || (_cache[15] = [
                _createElementVNode("span", null, "Assign your new virtual desktop to a team member.", -1),
                _createElementVNode("span", null, "You can assign it to an existing team member, or add a new team member", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          label: "Job Role",
                          "item-title": "Name",
                          "item-value": "id",
                          rules: [_ctx.rules.required],
                          items: _ctx.getJobRoles,
                          modelValue: _ctx.model.JobRoleId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.JobRoleId) = $event)),
                          outlined: ""
                        }, null, 8, ["rules", "items", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextField, {
                          rules: [_ctx.rules.EmailCheck],
                          label: "Email",
                          modelValue: _ctx.model.TmEmail,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.TmEmail) = $event)),
                          onFocousout: _ctx.validateTeammember
                        }, null, 8, ["rules", "modelValue", "onFocousout"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.tmDetailsDisabled || _ctx.rules.required],
                          label: "Country of Residence",
                          "item-title": "Name",
                          "item-value": "Id",
                          items: _ctx.countries,
                          modelValue: _ctx.model.TmCountry,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.TmCountry) = $event)),
                          disabled: _ctx.tmDetailsDisabled
                        }, null, 8, ["rules", "items", "modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextField, {
                          rules: [_ctx.tmDetailsDisabled || _ctx.rules.required],
                          label: "First Name",
                          modelValue: _ctx.model.TmFirstName,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.TmFirstName) = $event)),
                          disabled: _ctx.tmDetailsDisabled
                        }, null, 8, ["rules", "modelValue", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextField, {
                          rules: [_ctx.tmDetailsDisabled || _ctx.rules.required],
                          label: "Last Name",
                          modelValue: _ctx.model.TmLastName,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.TmLastName) = $event)),
                          disabled: _ctx.tmDetailsDisabled
                        }, null, 8, ["rules", "modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextField, {
                          disabled: _ctx.tmDetailsDisabled,
                          rules: [_ctx.tmDetailsDisabled || _ctx.rules.phoneNumber],
                          label: "Mobile Number",
                          modelValue: _ctx.model.TmMobile,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.TmMobile) = $event))
                        }, null, 8, ["disabled", "rules", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading", "disabled"]),
        _createVNode(_component_v_card, {
          flat: "",
          class: "mt-2",
          loading: _ctx.loading,
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardTitle, { title: "Engagement Details" }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "4",
                      md: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DateInputField, {
                          label: "Start date",
                          rules: [_ctx.rules.required],
                          "first-day-of-week": "1",
                          modelValue: _ctx.model.CommitmentStartDate,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.CommitmentStartDate) = $event)),
                          "hide-actions": "",
                          placeholder: "Start date",
                          "prepend-icon": "",
                          min: _ctx.getTodayDate
                        }, null, 8, ["rules", "modelValue", "min"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "4",
                      md: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DateInputField, {
                          label: "End date",
                          rules: [_ctx.rules.required],
                          "first-day-of-week": "1",
                          modelValue: _ctx.model.CommitmentEndDate,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.CommitmentEndDate) = $event)),
                          "hide-actions": "",
                          placeholder: "End date",
                          "prepend-icon": "",
                          min: _ctx.getMinDate
                        }, null, 8, ["rules", "modelValue", "min"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ComboBoxField, {
                          rules: [_ctx.rules.required],
                          label: "Project / Team Name",
                          items: _ctx.projects,
                          "item-title": "Name",
                          "item-value": "Name",
                          modelValue: _ctx.model.ProjectName,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.ProjectName) = $event)),
                          "return-object": false
                        }, null, 8, ["rules", "items", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading", "disabled"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_card_actions, { class: "justify-center mt-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_btn, {
          class: "primary_btn",
          prependIcon: "mdi-content-save",
          onClick: _ctx.showCompleteSetupModal,
          loading: _ctx.loading,
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode("Create")
          ])),
          _: 1
        }, 8, ["onClick", "loading", "disabled"])
      ]),
      _: 1
    }),
    (_ctx.showCompleteSetup)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 0,
          scrollable: "",
          modelValue: _ctx.showCompleteSetup,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showCompleteSetup) = $event)),
          "max-width": "1100",
          onKeydown: _cache[13] || (_cache[13] = _withKeys(($event: any) => (_ctx.showCompleteSetup = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CompleteSetup, {
              model: _ctx.model,
              projectLeads: _ctx.projectLeads,
              portfolioManagers: _ctx.portfolioManagers,
              publicHolidayZones: _ctx.publicHolidayZones,
              cpuValues: _ctx.cpuValues,
              ramValues: _ctx.ramValues,
              diskValues: _ctx.diskValues,
              billableItems: _ctx.billableItems,
              selectedCurrency: _ctx.selectedCurrency,
              selectedTax: _ctx.selectedTax,
              onCancel: _ctx.closeCompleteSetupModal
            }, null, 8, ["model", "projectLeads", "portfolioManagers", "publicHolidayZones", "cpuValues", "ramValues", "diskValues", "billableItems", "selectedCurrency", "selectedTax", "onCancel"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}