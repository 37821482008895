import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import dashboard from "../views/Dashboard/Dashboard.vue"
import Logout from "../components/Auth/Logout.vue"
import login from "../components/Auth/Login.vue"
import checkMagicLink from "../components/Auth/CheckMagicLink.vue"
import mainLayout from "../views/layouts/MainLayout.vue"
import Productivity from "../views/Productivity/Productivity.vue"
import Cost from "../views/Cost/Cost.vue"
import Search from "../views/Search/Search.vue"
import MyProjects from "../views/MyProjects/MyProjects.vue"
import NotificationList from "../components/User/NotificationList.vue"
import MyTraining from "../views/Training/RoadMap/MyTraining.vue"
import CourseList from "../views/Training/Course/CourseList.vue"
import CourseDetail from "../views/Training/Course/CourseDetail.vue"
import AddOrEditCourse from "../views/Training/Course/AddOrEditCourse.vue"
import AssessmentList from "../views/Training/Assessment/AssessmentList.vue"
import AddOrEditAssessment from "../views/Training/Assessment/AddOrEditAssessment.vue"
import AddOrEditRoadMap from "../views/Training/RoadMap/AddOrEditRoadMap.vue"
import AssessmentDetail from "../views/Training/Assessment/AssessmentDetail.vue"
import SearchWizard from "../views/Search/SearchWizard.vue"
import LoginLayout from "../components/Auth/Layouts/LoginLayout.vue"
import store from "@/store"
import VirtualDesktop from "../views/VirtualDesktop/VirtualDesktop.vue"
import Management from "../views/Management/Management.vue"
import ProjectLead from "../views/ProjectLead/ProjectLead.vue"
import PortfolioManager from "../views/PortfolioManager/PortfolioManager.vue"
import Planning from "../views/Planning/Planning.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/client",
  },
  {
    path: "/clientProfile",
    redirect: "/client/profile",
  },
  {
    path: "/login",
    redirect: "/authentication/login",
  },
  {
    path: "/checkMagicLink/:token",
    redirect: "/authentication/checkMagicLink/:token",
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/authentication",
    name: "authentication",
    component: LoginLayout,
    children: [
      {
        path: "",
        redirect: "login",
      },
      {
        path: "login",
        name: "login",
        meta: {
          title: "Login",
        },
        component: login,
      },
      {
        path: "checkMagicLink/:token",
        name: "CheckMagicLink",
        props: true,
        component: checkMagicLink,
        meta: {
          title: "Login",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    alias: "/login",
    component: login,
  },
  {
    path: "/client",
    name: "clientProfile",
    component: mainLayout,
    children: [
      {
        path: "",
        name: "preDashboard",
        redirect: "/client/management",
        meta: {
          roles: ["portfoliomanager", "projectlead", "client"],
        },
      },
      {
        path: "",
        name: "preDashboardProjectLead",
        redirect: "/client/productivity",
        meta: {
          roles: ["portfoliomanager", "projectlead", "client"],
        },
      },
      {
        path: "/client/planning",
        name: "planning",
        meta: {
          title: "Planning",
          icon: "mdi-account",
          roles: ["portfoliomanager", "projectlead", "client"],
        },
        component: Planning,
      },
      {
        path: "",
        name: "preDashboardTdm",
        redirect: "/client/training/roadMap",
        meta: {
          roles: ["tdm"],
        },
      },
      // {
      //   path: "dashboard",
      //   name: "clientDashboard",
      //   meta: {
      //     title: "Dashboard",
      //     icon: "mdi-account",
      //     roles: ["portfoliomanager", "projectlead", "client"],
      //   },
      //   component: dashboard,
      // },
      {
        path: "productivity",
        name: "productivity",
        meta: {
          title: "Productivity",
          icon: "mdi-chart-arc",
          roles: ["projectlead", "portfoliomanager", "client"],
        },
        component: Productivity,
      },
      {
        path: "cost",
        name: "cost",
        meta: {
          title: "Cost",
          icon: "mdi-file-tree",
          roles: ["portfoliomanager"],
        },
        component: Cost,
      },
      {
        path: "myProjects",
        name: "my-projects",
        meta: {
          title: "My Projects",
          icon: "mdi-file-multiple",
          roles: ["portfoliomanager", "projectlead", "client"],
        },
        component: MyProjects,
      },
      {
        path: "training/roadMap",
        name: "roadmap",
        meta: {
          title: "Training RoadMaps",
          icon: "mdi-account-school",
        },
        component: MyTraining,
      },
      {
        path: "training/roadMap/new",
        name: "addRoadmap",
        meta: {
          title: "Add a new RoadMap",
          icon: "mdi-account-school",
        },
        component: AddOrEditRoadMap,
      },
      {
        path: "training/roadMap/edit/:roadMapId",
        name: "editRoadmap",
        component: AddOrEditRoadMap,
        meta: {
          title: "Edit RoadMap",
          icon: "mdi-account-school",
        },
        props: true,
      },
      {
        path: "training/courses",
        name: "courses",
        meta: {
          title: "courses",
          icon: "mdi-school",
        },
        component: CourseList,
        props: {
          backButtonVisibility: false,
          addToCoursesButtonVisibility: false,
          assessmentBtnVisiblity: false,
          courseBtnVisiblity: true,
          showAsPage: true,
          topValue: "69px",
          heightValue: "545px",
          paddingValue: "35px",
        },
      },
      {
        path: "training/courses/view/:courseId",
        name: "course-detail",
        component: CourseDetail,
        props: true,
      },
      {
        path: "training/courses/new",
        name: "add-course",
        component: AddOrEditCourse,
        props: true,
      },
      {
        path: "training/courses/edit/:courseId",
        name: "edit-course",
        component: AddOrEditCourse,
        props: true,
      },
      {
        path: "training/assessment",
        name: "assessment-list",
        component: AssessmentList,
        meta: {
          title: "Assessments",
          icon: "mdi-book-clock",
        },
        props: {
          showAsPage: true,
          topValue: "69px",
          heightValue: "545px",
          paddingValue: "35px",
          backButtonVisibility: false,
          assessmentBtnVisiblity: true,
          courseBtnVisiblity: false,
        },
      },
      {
        path: "training/assessment/new",
        name: "add-assessment",
        component: AddOrEditAssessment,
      },
      {
        path: "training/assessment/edit/:assessmentId",
        name: "edit-assessment",
        component: AddOrEditAssessment,
        props: true,
      },
      {
        path: "training/assessment/view/:assessmentId",
        name: "assessment-detail",
        component: AssessmentDetail,
        props: true,
      },
      {
        path: "search",
        name: "search",
        meta: {
          title: "Search",
          icon: "mdi-search",
          roles: ["portfoliomanager"],
        },
        component: Search,
        props: true,
      },
      {
        path: "search/wizard",
        name: "searchWizard",
        meta: {
          title: "Search",
          icon: "mdi-search",
          roles: ["portfoliomanager"],
        },
        component: SearchWizard,
      },
      {
        path: "virtualDesktop",
        name: "virtualDesktop",
        meta: {
          title: "Smart Workbench",
          icon: "mdi-lock-smart",
          roles: ["portfoliomanager", "client"],
        },
        component: VirtualDesktop,
      },
      {
        path: "management",
        name: "management",
        meta: {
          title: "Management",
          icon: "mdi-file-tree",
          roles: ["portfoliomanager", "client"],
        },
        component: Management,
        props: true,
      },
      {
        path: "projectLead",
        name: "projectLead",
        meta: {
          title: "Project Lead",
          icon: "mdi-plus",
          roles: ["portfoliomanager", "projectlead", "client"],
        },
        component: ProjectLead,
      },
      {
        path: "portfolioManager",
        name: "portfolioManager",
        meta: {
          title: "Portfolio Manager",
          icon: "mdi-plus",
          roles: ["portfoliomanager", "projectlead", "client"],
        },
        component: PortfolioManager,
      },
      {
        path: "notifications",
        name: "Notification List",
        meta: {
          title: "Notification List",
          icon: "mdi-bell",
        },
        component: NotificationList,
      },
      {
        path: "/:pathMatch(.*)",
        redirect: "/client",
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/client",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch("showLoading")
  if (to) {
    if (to.meta && to.meta.roles) {
      var pageRoles = to.meta.roles as string[]
      var userRoles = store.state.clientInfo.roles
      if (!userRoles || userRoles.length == 0) {
        next("/login")
      }
      const found = userRoles.some((r) => pageRoles.indexOf(r) >= 0)
      if (found) {
        next()
      } else {
        if (store.getters.userIsTDM) {
          next({ name: "preDashboardTdm" })
        } else if (store.getters.userIsProjectLead) {
          next({ name: "preDashboardProjectLead" })
        } else {
          next({ name: "preDashboard" })
        }
      }
    }
    next()
  }
})
router.afterEach(() => {
  store.dispatch("hiddenLoading")
})

export default router
