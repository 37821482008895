<template>
  <v-tooltip location="bottom" v-if="alerts.length > 0" content-class="alert-container" :eager="false">
    <template v-slot:activator="{ props }">
      <div :class="[type == 'Urgent' ? 'alert-urgent' : type == 'Alert' ? 'alert-warning' : 'alert-info']" v-bind="props" class="alert-item">
        <v-icon class="alert-icon">
          {{ type == "Urgent" ? "mdi-close-octagon " : type == "Alert" ? "mdi-alert-rhombus" : "mdi-information" }}
        </v-icon>
        <span class="f-body-small alert-text">
          {{ type }}
        </span>
        <span class="alert-count f-body-extra-small" v-if="alerts.length > 0">{{ alerts.length }}</span>
      </div>
    </template>
    <div class="alert-content">
      <v-alert
        :text="item"
        v-for="(item, index) in alerts"
        :key="index"
        :type="type == 'Urgent' ? 'error' : type == 'Alert' ? 'warning' : 'info'"
        density="compact"
        class="alert-message" />
    </div>
  </v-tooltip>
</template>
<script lang="ts">
  import { defineComponent, PropType } from "vue"
  import { TeammemberWithVmDetails, TeammemberWithVmDetailsCommitmentAdminApprovalEnum } from "shared-components/src/services/openApi/api"
  export default defineComponent({
    props: {
      tooltip: {
        type: String,
      },
      icon: {
        type: String,
      },
      type: {
        type: String as PropType<"Info" | "Alert" | "Urgent">,
      },
      commitment: {
        type: Object as PropType<TeammemberWithVmDetails>,
      },
    },
    data() {
      return {
        alerts: [] as string[],
        approvalStatus: TeammemberWithVmDetailsCommitmentAdminApprovalEnum,
      }
    },
    computed: {},
    methods: {
      generateAlerts() {
        this.alerts = []
        if (this.type == "Urgent") {
          if (this.commitment?.NearToExpire) {
            this.alerts.push("This Commitment is near expiry")
          }
        }

        if (this.type == "Info") {
          if (this.commitment?.HaveUpcomingCommitment) {
            this.alerts.push("This Commitment already have a upcoming commitment")
          }
        }

        if (this.type == "Alert") {
          if (!this.isStatusApproved(this.commitment?.CommitmentAdminApproval)) {
            this.alerts.push("This commitment is awaiting Admin approval.")
          }
          if (!this.isStatusApproved(this.commitment?.CommitmentCustomerApproval)) {
            this.alerts.push("This commitment is awaiting Customer approval.")
          }
          if (!this.isStatusApproved(this.commitment?.CommitmentTeammemberApproval)) {
            this.alerts.push("This commitment is awaiting Team Member approval.")
          }
        }
      },
      isStatusApproved(status: TeammemberWithVmDetailsCommitmentAdminApprovalEnum | undefined) {
        return status == this.approvalStatus.ApprovedExplicit || status == this.approvalStatus.ApprovedInherited || status == undefined || status == null
      },
    },
    async created() {},
    watch: {
      commitment: {
        handler() {
          this.generateAlerts()
        },
        deep: true,
        immediate: true,
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";

  :deep(.alert-container) {
    background: transparent !important;
    padding: 0 !important;
  }
  .alert-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .alert-item {
    display: flex;
    gap: 4px;
    padding: 4px;
    @extend .b-r-5;
    height: min-content;
    background-color: $c_jet;
    border: 1px solid $c_dim_gray;
    justify-content: center;
    align-items: center;
    @extend .b-r-3;

    .alert-icon {
      font-size: 14px;
    }
    .alert-count {
      border-radius: 20px;
      color: $c_black;
      width: 17px;
      height: 17px;
      text-align: center;
      line-height: 17px;
    }

    &.alert-info {
      color: $c_platinum;
      .alert-count {
        background-color: $c_platinum;
      }
    }
    &.alert-warning {
      color: $c_mindaro;
      .alert-count {
        background-color: $c_mindaro;
      }
    }
    &.alert-urgent {
      color: $c_light_red;
      .alert-count {
        background-color: $c_light_red;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .alert-item {
      .alert-text {
        display: none;
      }
    }
  }
</style>
