<template>
  <v-card class="b-eerie-black" v-if="finalModel" :loading="loading" :disabled="loading">
    <v-card-text>
      <h1>Complete Setup</h1>
      <div class="setup-complete-container">
        <div>
          <h4>Order Summary</h4>
          <v-card class="mt-2">
            <v-card-text>
              <OrderItem @modify="modifyVd" :orderModel="getVdOrderModel" />
              <div v-if="finalModel.Addons?.TimesheetProcessingAddon?.Enabled" class="mt-3" @modify="modifyVd">
                <v-divider />
                <OrderItem class="mt-3" v-if="finalModel.Addons?.TimesheetProcessingAddon?.Enabled" @modify="showTimesheetProcessingModal" :orderModel="getTMOrderModel" />
              </div>
              <div v-if="finalModel.Addons?.CodeScanAddon?.Enabled" class="mt-3">
                <v-divider />
                <OrderItem class="mt-3" v-if="finalModel.Addons?.CodeScanAddon?.Enabled" :orderModel="getCSOrderModel" />
              </div>
            </v-card-text>
          </v-card>
          <v-divider class="mt-6 mb-6" />
          <h4 v-if="!finalModel.Addons?.TimesheetProcessingAddon?.Enabled || !finalModel.Addons?.CodeScanAddon?.Enabled">Add Services To Manage Your Team</h4>
          <v-row class="mt-2 mb-6">
            <v-col cols="12" sm="6" md="4" v-if="!finalModel.Addons?.TimesheetProcessingAddon?.Enabled">
              <ServiceItem
                @add="showTimesheetProcessingModal"
                type="TimesheetProcessing"
                :fee="getTimesheetFee"
                title="Timesheet Processing"
                description="Learn more about Timesheet Processing" />
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="!finalModel.Addons?.CodeScanAddon?.Enabled">
              <ServiceItem type="CodeScan" :fee="getCodeScanFee" title="Code Scan" description="Learn more about Code Scan" @add="addCodeScanService" />
            </v-col>
          </v-row>
          <v-card class="border-white-1">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8">
                  <PriceCalculator :feePerHour="getTotalFeePerHour" :isTotal="true" :currency="selectedCurrency" />
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-end justify-end">
                  <v-btn class="primary_btn setup-complete-btn" @click="createVm">Complete Setup</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-card-text>
  </v-card>
  <v-dialog v-if="showTimesheetProcessing" v-model="showTimesheetProcessing" max-width="1000" @keydown.esc="showTimesheetProcessing = false">
    <TimesheetProcessing
      :projectLeads="projectLeads"
      :portfolioManagers="portfolioManagers"
      :publicHolidayZones="publicHolidayZones"
      @cancel="closeTimesheetProcessingModal"
      @save="saveTimesheetProcessing"
      :model="finalModel" />
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue"
  import store from "@/store"
  import { ClientCreateVMRequest } from "shared-components/src/services/openApi/api"
  import CommitmentService from "@/services/CommitmentService"
  import PriceCalculator from "./PriceCalculator.vue"
  import TimesheetProcessing from "./TimesheetProcessing.vue"
  import OrderItem from "./OrderItem.vue"
  import ServiceItem from "./ServiceItem.vue"
  import moment from "moment"

  export default defineComponent({
    props: ["cpuValues", "ramValues", "diskValues", "projectLeads", "portfolioManagers", "publicHolidayZones", "model", "billableItems", "selectedCurrency", "selectedTax"],
    components: { VirtualDesktopAddon, PriceCalculator, TimesheetProcessing, OrderItem, ServiceItem },
    data() {
      return {
        showTimesheetProcessing: false,
        loading: false,
        finalModel: null as null | ClientCreateVMRequest,
      }
    },
    async mounted() {
      this.finalModel = JSON.parse(JSON.stringify(this.model))
      if (this.finalModel && this.finalModel.Addons && this.finalModel.Addons.VirtualDesktopAddon) {
        this.finalModel.Addons.VirtualDesktopAddon.Cpu = Number(Object.values(this.cpuValues)[this.model.Addons.VirtualDesktopAddon.Cpu ?? 0])
        this.finalModel.Addons.VirtualDesktopAddon.Ram = Number(Object.values(this.ramValues)[this.model.Addons.VirtualDesktopAddon.Ram ?? 0])
        this.finalModel.Addons.VirtualDesktopAddon.Disk = Number(Object.values(this.diskValues)[this.model.Addons.VirtualDesktopAddon.Disk ?? 0])
      }
    },
    methods: {
      modifyVd() {
        this.$emit("cancel")
      },
      showTimesheetProcessingModal() {
        this.showTimesheetProcessing = true
      },
      closeTimesheetProcessingModal() {
        this.showTimesheetProcessing = false
      },
      saveTimesheetProcessing() {
        this.closeTimesheetProcessingModal()
        if (this.finalModel && this.finalModel.Addons && this.finalModel.Addons.TimesheetProcessingAddon) {
          this.finalModel.Addons.TimesheetProcessingAddon.Enabled = true
        }
      },
      async createVm() {
        if (this.finalModel) {
          this.loading = true
          try {
            this.finalModel.CommitmentStartDate = moment(this.finalModel.CommitmentStartDate).format("YYYY-MM-DDTHH:mm:ss")
            this.finalModel.CommitmentEndDate = moment(this.finalModel.CommitmentEndDate).format("YYYY-MM-DDTHH:mm:ss")
            if (this.finalModel && this.finalModel.Addons && this.finalModel.Addons.VirtualDesktopAddon && this.finalModel.Addons.VirtualDesktopAddon.Enabled) {
              this.finalModel.Addons.VirtualDesktopAddon.Currency = this.selectedCurrency
              this.finalModel.Addons.VirtualDesktopAddon.Tax = this.selectedTax
            }
            if (this.finalModel && this.finalModel.Addons && this.finalModel.Addons.CodeScanAddon && this.finalModel.Addons.CodeScanAddon.Enabled) {
              this.finalModel.Addons.CodeScanAddon.Currency = this.selectedCurrency
              this.finalModel.Addons.CodeScanAddon.Tax = this.selectedTax
            }
            if (this.finalModel && this.finalModel.Addons && this.finalModel.Addons.TimesheetProcessingAddon && this.finalModel.Addons.TimesheetProcessingAddon.Enabled) {
              this.finalModel.Addons.TimesheetProcessingAddon.Currency = this.selectedCurrency
              this.finalModel.Addons.TimesheetProcessingAddon.Tax = this.selectedTax
            }
            await CommitmentService.createVmAndCommitment(this.finalModel)
            store.dispatch("showSuccessMessage", "VM request sent successfully")
            this.$router.push({ name: "management" })
          } finally {
            this.loading = false
          }
        }
      },
      getProjectLeadName(id: string) {
        return this.projectLeads.find((item: any) => item.id == id).Name
      },
      getPublicHolidayZoneName(id: string) {
        return this.publicHolidayZones.find((item: any) => item.id == id).Name
      },
      addCodeScanService() {
        if (this.finalModel && this.finalModel.Addons && this.finalModel.Addons.CodeScanAddon) {
          this.finalModel.Addons.CodeScanAddon.Enabled = true
        }
      },
    },
    computed: {
      getVdOrderModel() {
        return {
          Title: "Virtual Desktop",
          Info1: `Cpu: ${this.finalModel?.Addons?.VirtualDesktopAddon?.Cpu} Core`,
          Info2: `Ram: ${this.finalModel?.Addons?.VirtualDesktopAddon?.Ram} GB`,
          Info3: `Disk: ${this.finalModel?.Addons?.VirtualDesktopAddon?.Disk} GB`,
          Currency: this.selectedCurrency,
          FeePerHour: this.getVmFee,
        }
      },
      getTMOrderModel() {
        return {
          Title: "Timesheet Processing",
          Info1: `Project Lead: ${this.getProjectLeadName(this.finalModel?.CommitmentProjectLeadId ?? "")}`,
          Info2: `TimeZone: ${this.finalModel?.CommitmentTimeZone}`,
          Info3: `Public Holiday Zone: ${this.getPublicHolidayZoneName(this.finalModel?.CommitmentPublicHolidayZoneId ?? "")}`,
          Currency: this.selectedCurrency,
          FeePerHour: this.getTimesheetFee,
        }
      },
      getCSOrderModel() {
        return {
          Title: "Code Scan",
          Info1: ``,
          Info2: ``,
          Info3: ``,
          Currency: this.selectedCurrency,
          FeePerHour: this.getCodeScanFee,
        }
      },
      getCodeScanFee() {
        return this.billableItems?.find((x: any) => x.Code == "COSCR")?.Fee ?? 0
      },
      getTimesheetFee() {
        return this.billableItems?.find((x: any) => x.Code == "TSMGR")?.Fee ?? 0
      },
      getVmFee() {
        const baseFee = this.billableItems?.find((x: any) => x.Code == "VDICR")?.Fee ?? 0
        if (this.finalModel?.Addons?.VirtualDesktopAddon?.Cpu && this.finalModel?.Addons?.VirtualDesktopAddon?.Ram && this.finalModel?.Addons?.VirtualDesktopAddon?.Disk) {
          const cpu = this.finalModel.Addons.VirtualDesktopAddon.Cpu
          const ram = this.finalModel.Addons.VirtualDesktopAddon.Ram
          const disk = this.finalModel.Addons.VirtualDesktopAddon.Disk
          const cpuExtraFee = ((cpu - 6) / 200).toFixed(2)
          const ramExtraFee = ((ram - 8) / 800).toFixed(2)
          const diskExtraFee = ((disk - 128) / 12800).toFixed(2)
          return baseFee + Number(cpuExtraFee) + Number(ramExtraFee) + Number(diskExtraFee)
        }
        return 0
      },
      getTotalFeePerHour() {
        const tmFee = this.finalModel?.Addons?.TimesheetProcessingAddon?.Enabled ? this.getTimesheetFee : 0
        const csFee = this.finalModel?.Addons?.CodeScanAddon?.Enabled ? this.getCodeScanFee : 0

        return this.getVmFee + tmFee + csFee
      },
    },
  })
</script>
<style scoped lang="scss">
  .setup-complete-container {
    padding: 24px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .setup-complete-btn {
      width: 100%;
    }
  }
</style>
