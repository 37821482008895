<template>
  <div class="alert-container">
    <AlertItem type="Info" :commitment="commitment" />
    <AlertItem type="Alert" :commitment="commitment" />
    <AlertItem type="Urgent" :commitment="commitment" />
  </div>
</template>
<script lang="ts">
  import { defineComponent, PropType } from "vue"
  import { TeammemberWithVmDetails } from "shared-components/src/services/openApi/api"
  import AlertItem from "./AlertItem.vue"

  export default defineComponent({
    components: { AlertItem },
    props: {
      commitment: {
        type: Object as PropType<TeammemberWithVmDetails>,
      },
    },
    data() {
      return {}
    },
    computed: {},
  })
</script>
<style lang="scss" scoped>
  .alert-container {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
</style>
