import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "f-body-small alert-text" }
const _hoisted_2 = {
  key: 0,
  class: "alert-count f-body-extra-small"
}
const _hoisted_3 = { class: "alert-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_ctx.alerts.length > 0)
    ? (_openBlock(), _createBlock(_component_v_tooltip, {
        key: 0,
        location: "bottom",
        "content-class": "alert-container",
        eager: false
      }, {
        activator: _withCtx(({ props }) => [
          _createElementVNode("div", _mergeProps({
            class: [_ctx.type == 'Urgent' ? 'alert-urgent' : _ctx.type == 'Alert' ? 'alert-warning' : 'alert-info']
          }, props, { class: "alert-item" }), [
            _createVNode(_component_v_icon, { class: "alert-icon" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.type == "Urgent" ? "mdi-close-octagon " : _ctx.type == "Alert" ? "mdi-alert-rhombus" : "mdi-information"), 1)
              ]),
              _: 1
            }),
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.type), 1),
            (_ctx.alerts.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.alerts.length), 1))
              : _createCommentVNode("", true)
          ], 16)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (item, index) => {
              return (_openBlock(), _createBlock(_component_v_alert, {
                text: item,
                key: index,
                type: _ctx.type == 'Urgent' ? 'error' : _ctx.type == 'Alert' ? 'warning' : 'info',
                density: "compact",
                class: "alert-message"
              }, null, 8, ["text", "type"]))
            }), 128))
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}