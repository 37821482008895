<template>
  <div>
    <v-row class="ga-2 justify-space-between">
      <v-col cols="12" sm="8" md="9">
        <v-row>
          <v-col cols="12" md="5" class="d-flex flex-column">
            <span class="f-sub-heading c-white">{{ orderModel.Title }}</span>
            <span class="f-body-2">{{ orderModel.Info1 }}</span>
            <span class="f-body-2">{{ orderModel.Info2 }}</span>
            <span class="f-body-2">{{ orderModel.Info3 }}</span>
          </v-col>
          <v-col cols="12" md="2" class="f-sub-heading c-white">QTY: 1</v-col>
          <v-col cols="12" md="5" class="d-flex flex-column ga-2">
            <PriceCalculator :feePerHour="orderModel.FeePerHour" :isOrderItem="true" :currency="orderModel.Currency" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-btn class="tertiary_btn w-100" @click="modify()">Modify</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">More Information On Transparent Pricing</v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import PriceCalculator from "./PriceCalculator.vue"
  export default defineComponent({
    props: ["orderModel"],
    components: { PriceCalculator },
    data() {
      return {}
    },
    async mounted() {},
    methods: {
      modify() {
        this.$emit("modify")
      },
    },
    computed: {},
  })
</script>
<style scoped lang="scss"></style>
