import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_LoadingV2 = _resolveComponent("LoadingV2")!
  const _component_ProjectItem = _resolveComponent("ProjectItem")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ProjectDetail = _resolveComponent("ProjectDetail")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-title-left" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_btn, {
          class: "primary_btn",
          "prepend-icon": "mdi-plus",
          onClick: _ctx.showNewProject,
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("New Project")
          ])),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingV2, {
          key: 0,
          darkMode: true
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, { class: "mt-3" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
          return (_openBlock(), _createBlock(_component_v_col, {
            cols: "12",
            key: project.Id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ProjectItem, {
                project: project,
                projectList: _ctx.getProjectList,
                ref_for: true,
                ref: project.Id,
                details: _ctx.details,
                skills: _ctx.skills,
                projectLeads: _ctx.projectLeads,
                publicHolidayZones: _ctx.publicHolidayZones,
                officeLocations: _ctx.officeLocations,
                lineManagers: _ctx.lineManagers,
                tdms: _ctx.tdms,
                portfolioManagers: _ctx.portfolioManagers,
                officeSpaces: _ctx.officeSpaces
              }, null, 8, ["project", "projectList", "details", "skills", "projectLeads", "publicHolidayZones", "officeLocations", "lineManagers", "tdms", "portfolioManagers", "officeSpaces"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showProjectModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showProjectModal) = $event)),
      "max-width": "900"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ProjectDetail, {
          onClose: _ctx.closeProjectPopup,
          onSavedProject: _ctx.savedProject,
          project: _ctx.selectedProject
        }, null, 8, ["onClose", "onSavedProject", "project"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}