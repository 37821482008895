<template>
  <div>
    <v-form ref="mainForm">
      <v-card flat :loading="loading" :disabled="loading">
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <TextField :disabled="loading" :loading="loading" :rules="[rules.EmailCheck]" label="Email" v-model="model.email"  @focousout="validateTeammember" />
            </v-col>
            <v-col cols="4">
              <TextField :disabled="tmDetailsDisabled || loading" :loading="loading" :rules="[rules.required]" label="First Name" v-model="model.firstName" />
            </v-col>
            <v-col cols="4">
              <TextField :disabled="tmDetailsDisabled || loading" :loading="loading" :rules="[rules.required]" label="Last Name" v-model="model.lastName" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <div class="actionBtns mt-3">
      <v-btn class="primary_btn" @click="invite" :loading="loading">Invite</v-btn>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import rules from "shared-components/src/utils/validations"
  import { ClientInviteTeammemberRequest, ClientTeammemberApi } from "shared-components/src/services/openApi/api"
  import TeammemberService from "@/services/TeammemberService"

  export default defineComponent({
    props: [],
    data() {
      return {
        lastEmailValidated: "",
        loading: false,
        rules,
        model: {} as ClientInviteTeammemberRequest,
        tmDetailsDisabled: true,
      }
    },
    mounted() {},
    methods: {
      async validateTeammember() {
        if (this.model.email && this.model.email != "" && this.lastEmailValidated != this.model.email) {
          this.model.firstName = ""
          this.model.lastName = ""
          this.lastEmailValidated = this.model.email
          const isValidEmail = this.rules.Email(this.model.email)
          if (isValidEmail == "") {
            this.loading = true
            try {
              const response = (await new ClientTeammemberApi().clientValidateTeammember(this.model.email)).data as any
              if (response.Id) {
                this.model.firstName = response.FirstName
                this.model.lastName = response.LastName
                this.tmDetailsDisabled = true
              } else {
                this.model.firstName = ""
                this.model.lastName = ""
                this.tmDetailsDisabled = false
              }
            } catch (error: any) {
              this.tmDetailsDisabled = true
            } finally {
              this.loading = false
            }
          }
        }
      },
      async invite() {
        const isValid = await (this.$refs.mainForm as any).validate()
        if (isValid.valid) {
          this.loading = true
          try {
            const response = await TeammemberService.inviteTeammember(this.model)
            this.$emit("InviteTM", response)
          } finally {
            this.loading = false
          }
        }
      },
    },
  })
</script>
<style lang="scss" scoped>
  .actionBtns {
    display: flex;
    justify-content: right;
  }
</style>
