import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-text ml-3"
}
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { class: "expanded-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_NumberField = _resolveComponent("NumberField")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_AddOrEditQuestion = _resolveComponent("AddOrEditQuestion")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "assessment-form",
    disabled: _ctx.isLoading,
    loading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "form-inputs" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmMain",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "3" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        class: "fields",
                        modelValue: _ctx.assessmentModel.Title,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assessmentModel.Title) = $event)),
                        label: "Title",
                        "prepend-icon": "mdi-rename",
                        rules: [_ctx.rules.required],
                        clearable: ""
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "3" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        class: "fields",
                        modelValue: _ctx.assessmentModel.Objective,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assessmentModel.Objective) = $event)),
                        label: "Objective",
                        "prepend-icon": "mdi-code-tags",
                        clearable: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NumberField, {
                        class: "fields",
                        modelValue: _ctx.assessmentModel.PassScore,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.assessmentModel.PassScore) = $event)),
                        label: "Pass Score",
                        "prepend-icon": "mdi-check-circle-outline"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ComboBoxField, {
                        clearable: "",
                        chips: "",
                        multiple: "",
                        label: "Tag",
                        items: _ctx.tags,
                        "item-title": "Title",
                        "item-value": "Title",
                        "closable-chips": "",
                        modelValue: _ctx.SelectedTags,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.SelectedTags) = $event)),
                        "prepend-icon": "mdi-tag",
                        "return-object": false
                      }, null, 8, ["items", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "align-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "10",
                    class: "pt-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextAreaField, {
                        class: "pt-0",
                        modelValue: _ctx.assessmentModel.Description,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.assessmentModel.Description) = $event)),
                        label: "Description",
                        rows: "3",
                        "prepend-icon": "mdi-pencil",
                        clearable: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_checkbox, {
                        modelValue: _ctx.assessmentModel.IsRestartable,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.assessmentModel.IsRestartable) = $event)),
                        label: "Is Restartable",
                        "hide-details": ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "mb-5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    onClick: _ctx.addQuestion,
                    "prepend-icon": "mdi-plus",
                    class: "secondary_btn"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("add new question")
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  (_ctx.questionIsEmpty)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "* One Question is required"))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    expanded: _ctx.expanded,
                    "onUpdate:expanded": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.expanded) = $event)),
                    "show-expand": "",
                    items: _ctx.assessmentModel.Questions,
                    headers: _ctx.tableHeaders,
                    "item-value": "Id",
                    class: "tabel",
                    ref: "table"
                  }, {
                    "item.Title": _withCtx(({ item }) => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.truncate(item.Title)), 1)
                    ]),
                    "item.Technologies": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.getTechnologyName(item.Technologies)), 1)
                    ]),
                    "item.Editors": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.Editors && item.Editors.length > 0 ? _ctx.getEditorName(item.Editors) : "-"), 1)
                    ]),
                    "item.Level": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.Level ? item.Level : "-"), 1)
                    ]),
                    "item.Duration": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.Duration ? _ctx.formatDuration(item.Duration) : "-"), 1)
                    ]),
                    "item.actions": _withCtx(({ item }) => [
                      _createVNode(_component_v_icon, {
                        size: "small",
                        class: "me-2",
                        onClick: ($event: any) => (_ctx.editQuestion(item))
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("mdi-pencil")
                        ])),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_v_icon, {
                        size: "small",
                        onClick: ($event: any) => (_ctx.deleteQuestion(item))
                      }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode("mdi-delete")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    "expanded-row": _withCtx(({ columns, item }) => [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", {
                          colspan: columns.length
                        }, [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_v_row, { class: "mb-3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Question:", -1)),
                                    _createTextVNode(" " + _toDisplayString(item.Question), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_row, { class: "mb-3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Answer:", -1)),
                                    _createTextVNode(" " + _toDisplayString(item.Answer ? item.Answer : "-"), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_row, { class: "mb-3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "6" }, {
                                  default: _withCtx(() => [
                                    _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Technologies:", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.getTechnologyName(item.Technologies)), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, { cols: "6" }, {
                                  default: _withCtx(() => [
                                    _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Editors:", -1)),
                                    _createTextVNode(" " + _toDisplayString(item.Editors && item.Editors.length > 0 ? _ctx.getEditorName(item.Editors) : "-"), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_row, { class: "mb-3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "6" }, {
                                  default: _withCtx(() => [
                                    _cache[17] || (_cache[17] = _createElementVNode("strong", null, "Level:", -1)),
                                    _createTextVNode(" " + _toDisplayString(item.Level ? item.Level : "-"), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, { cols: "6" }, {
                                  default: _withCtx(() => [
                                    _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Duration:", -1)),
                                    _createTextVNode(" " + _toDisplayString(item.Duration ? _ctx.formatDuration(item.Duration) : "-"), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            (item.Evaluations && item.Evaluations.length > 0)
                              ? (_openBlock(), _createBlock(_component_v_row, {
                                  key: 0,
                                  class: "mb-3"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                                        _createElementVNode("strong", null, "Evaluations:", -1)
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.Evaluations, (evaluation, index) => {
                              return (_openBlock(), _createBlock(_component_v_row, {
                                key: index,
                                class: "evaluation-items"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Evaluation:", -1)),
                                      _createTextVNode(" " + _toDisplayString(evaluation.Criteria), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _cache[21] || (_cache[21] = _createElementVNode("strong", null, "Point:", -1)),
                                      _createTextVNode(" " + _toDisplayString(evaluation.Point), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ])
                        ], 8, _hoisted_2)
                      ])
                    ]),
                    _: 1
                  }, 8, ["expanded", "items", "headers"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            onClick: _ctx.cancel,
            class: "secondary_btn"
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode("Cancel")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            onClick: _ctx.save,
            class: "primary_btn_v2"
          }, {
            default: _withCtx(() => _cache[23] || (_cache[23] = [
              _createTextVNode("Save")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showAddQuestionModal,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showAddQuestionModal) = $event)),
        width: "1024",
        height: "600",
        class: "scrollable-dialog"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AddOrEditQuestion, {
            onCloseQuestionModal: _ctx.closeQuestionModal,
            onSaveQuestion: _ctx.saveQuestion,
            editors: _ctx.editors,
            technologies: _ctx.technologies,
            question: _ctx.selectedQuestionToEdit
          }, null, 8, ["onCloseQuestionModal", "onSaveQuestion", "editors", "technologies", "question"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showDeleteDialog,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showDeleteDialog) = $event)),
        "max-width": "500px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "text-h5" }, {
                default: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createTextVNode("Are you sure you want to delete this item?")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "secondary_btn",
                    onClick: _ctx.cancelDeletePopup
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    class: "primary_btn",
                    onClick: _ctx.deleteTableItems
                  }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode("Delete")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["disabled", "loading"]))
}