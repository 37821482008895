<template>
  <v-dialog v-model="showSearchDialog" persistent max-width="1000px" :loading="dialogLoading" :disabled="dialogLoading" scrollable>
    <div class="close-btn">
      <v-btn icon="mdi-close" variant="tonal" @click="closeDialog"></v-btn>
    </div>
    <v-stepper hide-actions alt-labels :items="commitmentSteps" ref="stepper" v-model="stepState">
      <template v-slot:item.1>
        <v-card flat class="search-background">
          <v-tabs v-model="teammemberTabs" bg-color="primary">
            <v-tab value="CandidatesShortlisted">Candidate Shortlist</v-tab>
            <v-tab value="search">Search Database (AI)</v-tab>
            <v-tab value="invite">Someone You Know</v-tab>
            <v-tab value="jobDescription">Delegate to CODE CLAN</v-tab>
          </v-tabs>

          <v-card-text>
            <v-tabs-window v-model="teammemberTabs">
              <v-tabs-window-item value="CandidatesShortlisted">
                <v-progress-circular :width="3" :size="70" color="red" indeterminate v-if="isCandidatesLoading" class="ma-auto d-flex justify-center"></v-progress-circular>
                <p v-else-if="listOfCandidatesShortlisted.length == 0" class="ma-2 text-center text-h6 text-white">No Data</p>
                <v-row v-else>
                  <v-col cols="12" sm="12" md="6" lg="4" v-for="(item, index) of listOfCandidatesShortlisted" :key="index">
                    <EmployeeItem :employee="item.TeammemberInfo" :skills="skills" :details="details" :showInScene="false" @ViewTmDetails="viewTmDetails" />
                  </v-col>
                </v-row>
              </v-tabs-window-item>
              <v-tabs-window-item value="search">
                <Search @ViewTmDetails="viewTmDetails" :positionSkills="getPositionSkills" :positionRole="getPositionJobRole" :positionJD="getPostionJD" :showInScene="true" />
              </v-tabs-window-item>

              <v-tabs-window-item value="invite">
                <InviteTeammember @InviteTM="inviteTeammember" />
              </v-tabs-window-item>
              <v-tabs-window-item value="jobDescription">
                <SearchByJD :positionJD="getPostionJD" @ContinueJD="ContinueJD" />
              </v-tabs-window-item>
            </v-tabs-window>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:item.2>
        <Details
          :selectedTM="selectedTM"
          :selectedProject="project"
          :projectLeads="projectLeads"
          :publicHolidayZones="publicHolidayZones"
          :projects="projects"
          @BackStep="backStep"
          :portfolioManagers="portfolioManagers"
          @NextStep="updateCommitmentDetails"
          :isJdSearch="isJdSearch"></Details>
      </template>

      <template v-slot:item.3>
        <Location
          @BackStep="backStep"
          @NextStep="updateLocationTypeDetails"
          @saveLocation="saveOfficeLocation"
          :officeLocations="officeLocations"
          :isJdSearch="isJdSearch"></Location>
      </template>

      <template v-slot:item.4>
        <AddOns
          @BackStep="backStep"
          @NextStep="saveCommitment"
          :lineManagers="lineManagers"
          :tdms="tdms"
          :loading="loading"
          :officeSpaces="officeSpaces"
          :commitmentModel="model"></AddOns>
      </template>
    </v-stepper>
    <v-dialog v-model="confirmDialog" max-width="600">
      <v-card>
        <v-card-text>Are you sure you want to close the Assign Team Member?</v-card-text>
        <v-card-actions>
          <v-btn class="secondary_btn" @click="noConfirm">No</v-btn>
          <v-btn class="primary_btn" @click="yesConfirm">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>

  <v-dialog v-if="showTMDetails && selectedTM" v-model="showTMDetails" class="custom-modal">
    <div class="inner">
      <CVTemplate1
        :employeeId="selectedTM.Id ?? ''"
        :skills="skills"
        :details="details"
        @click.stop.prevent
        @close="showTMDetails = false"
        @AddToMyTeam="assignTmToPosition"></CVTemplate1>
    </div>
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import Search from "../../Search/Search.vue"
  import EmployeeItem from "../../Search/EmployeeItem.vue"
  import CVTemplate1 from "@/components/CVTemplate/CVTemplate1.vue"
  import Details from "./Details.vue"
  import Location from "./Location.vue"
  import AddOns from "./AddOns.vue"
  import InviteTeammember from "./InviteTeammember.vue"
  import SearchByJD from "./SearchByJD.vue"
  import {
    ClientCommitmentApi,
    ClientSearchTeammemberResponseModel,
    CommitmentWithAddons,
    CommitmentAddons,
    PositionDetailsModel,
    PositionJDAndDetailsRequestModel,
    PositionApplicationWithTeammemberSearchInfoResponseModel,
    PositionApi,
    BaseRequestModel,
  } from "shared-components/src/services/openApi/api"
  import PositionService from "@/services/PositionService"
  import store from "@/store"
  import moment from "moment"

  export default defineComponent({
    components: {
      Search,
      CVTemplate1,
      Details,
      Location,
      AddOns,
      InviteTeammember,
      SearchByJD,
      EmployeeItem,
    },
    props: [
      "skills",
      "details",
      "project",
      "projectLeads",
      "portfolioManagers",
      "publicHolidayZones",
      "projects",
      "officeLocations",
      "dialogLoading",
      "position",
      "lineManagers",
      "officeSpaces",
      "tdms",
    ],
    data() {
      return {
        confirmDialog: false,
        isCandidatesLoading: false,
        teammemberTabs: null,
        showSearchDialog: true,
        loading: false,
        showTMDetails: false,
        listOfCandidatesShortlisted: [] as PositionApplicationWithTeammemberSearchInfoResponseModel[],
        selectedTM: null as null | ClientSearchTeammemberResponseModel,
        endDatePickerMenu: false,
        startDatePickerMenu: false,
        stepState: 1,
        commitmentSteps: ["Team member", "Details", "Location", "Add-ons"],
        model: {} as CommitmentWithAddons,
        isJdSearch: false,
        JobDescriptionByJdSearch: "",
      }
    },
    methods: {
      async fetchListOfCandidatesShortlisted() {
        this.isCandidatesLoading = true
        var request = { fields: { PositionId: this.position.Id, Status: "Shortlisted" } } as BaseRequestModel
        this.listOfCandidatesShortlisted = (await new PositionApi().getPositionApplicationForClient(request)).data
        this.isCandidatesLoading = false
      },
      noConfirm() {
        this.confirmDialog = false
      },
      yesConfirm() {
        this.confirmDialog = false
        this.$emit("OnClose")
      },
      ContinueJD(jobDescription: any) {
        this.isJdSearch = true
        this.JobDescriptionByJdSearch = jobDescription
        ;(this.$refs.stepper as any).next()
      },
      inviteTeammember(event: any) {
        this.isJdSearch = false
        this.selectedTM = event
        ;(this.$refs.stepper as any).next()
      },
      saveOfficeLocation(event: any) {
        this.$emit("saveLocation", event)
      },
      backStep() {
        ;(this.$refs.stepper as any).prev()
      },
      nextStep() {
        ;(this.$refs.stepper as any).next()
      },
      updateCommitmentDetails(event: any) {
        this.model.StartDate = moment(event.StartDate).format("YYYY-MM-DDTHH:mm:ss")
        this.model.EndDate = moment(event.EndDate).format("YYYY-MM-DDTHH:mm:ss")
        this.model.HoursPerWeek = Number(event.HoursPerWeek)
        this.model.HoursPerWeekCasual = event.HoursPerWeekRadio
        this.model.PublicHolidayZoneId = event.PublicHolidayZoneId
        this.model.ProjectLeadId = event.ProjectLeadId
        this.model.ProjectId = event.ProjectId
        this.model.TeamMemberId = event.TeamMemberId
        this.model.PortfolioManagerId = event.PortfolioManagerId
        this.nextStep()
      },
      updateLocationTypeDetails(event: any) {
        this.model.LocationType = event.LocationType
        this.model.TimeZone = event.TimeZone
        this.model.StartWorkClock = event.From
        this.model.EndWorkClock = event.To
        this.model.WorkingDays = event.WorkingDays
        this.model.DaysOfWeekInOffice = event.WorkingDaysInOffice
        this.model.OfficeDaysPerWeek = event.NoOfficeDaysPerWeek
        this.model.OfficeLocationId = event.OfficeLocationId
        this.nextStep()
      },
      async saveCommitment(addonsModel: any) {
        this.loading = true
        this.model.Addons = {
          CodeScanAddon: { Enabled: false },
          TdmAddon: { Enabled: false },
          TimesheetProcessingAddon: { Enabled: false },
          PayrollAddon: { Enabled: false },
          CodeClanFundAddon: { Enabled: false },
          VirtualDesktopAddon: { Enabled: false },
          LineManagerAddon: { Enabled: false },
          ContractAddon: { Enabled: false },
        } as CommitmentAddons

        if (addonsModel.timeSheetProcessing.Enabled) {
          this.model.Addons.TimesheetProcessingAddon = {
            Amount: addonsModel.timeSheetProcessing.Amount,
            Currency: addonsModel.timeSheetProcessing.Currency,
            Enabled: true,
            Tax: addonsModel.timeSheetProcessing.Tax,
          }
        }

        if (addonsModel.virtualDesktop.Enabled) {
          this.model.Addons.VirtualDesktopAddon = {
            Amount: addonsModel.virtualDesktop.Amount,
            Cpu: addonsModel.virtualDesktop.Cpu,
            Currency: addonsModel.virtualDesktop.Currency,
            Disk: addonsModel.virtualDesktop.Disk,
            Enabled: true,
            Level: addonsModel.virtualDesktop.Level,
            Location: addonsModel.virtualDesktop.Location,
            OperatingSystem: addonsModel.virtualDesktop.OperatingSystem,
            Ram: addonsModel.virtualDesktop.Ram,
            Tax: addonsModel.virtualDesktop.Tax,
          }
        }

        if (addonsModel.lineManager.Enabled) {
          this.model.Addons.LineManagerAddon = {
            Enabled: true,
            LineManagerId: addonsModel.lineManager.LineManagerId,
            Amount: addonsModel.lineManager.Amount,
            Currency: addonsModel.lineManager.Currency,
            Tax: addonsModel.lineManager.Tax,
          }
        }

        if (addonsModel.payRoll.Enabled) {
          this.model.Addons.PayrollAddon = {
            Enabled: true,
            SalaryAmount: addonsModel.payRoll.SalaryAmount,
            SalaryCurrency: addonsModel.payRoll.SalaryCurrency,
            SalaryInterval: addonsModel.payRoll.SalaryInterval,
            SalaryTax: addonsModel.payRoll.SalaryTax,
            PayrollAmount: addonsModel.payRoll.PayrollAmount,
            PayrollCurrency: addonsModel.payRoll.PayrollCurrency,
            PayrollTax: addonsModel.payRoll.PayrollTax,
          }
        }

        if (addonsModel.contract.Enabled) {
          this.model.Addons.ContractAddon = {
            Enabled: true,
            Amount: addonsModel.contract.Amount,
            Currency: addonsModel.contract.Currency,
            ContactVerification: addonsModel.contract.ContactVerification,
            ContractManagement: addonsModel.contract.ContractManagement,
            IDVerification: addonsModel.contract.IDVerification,
            Tax: addonsModel.contract.Tax,
          }
        }

        if (addonsModel.codeScan.Enabled) {
          this.model.Addons.CodeScanAddon = {
            Enabled: true,
            Amount: addonsModel.codeScan.Amount,
            Currency: addonsModel.codeScan.Currency,
            Tax: addonsModel.codeScan.Tax,
          }
        }

        if (addonsModel.tdm.Enabled) {
          this.model.Addons.TdmAddon = {
            Enabled: true,
            Amount: addonsModel.tdm.Amount,
            Currency: addonsModel.tdm.Currency,
            TdmId: addonsModel.tdm.TdmId,
            Tax: addonsModel.tdm.Tax,
          }
        }

        if (addonsModel.codeClanFund.Enabled) {
          this.model.Addons.CodeClanFundAddon = {
            Enabled: true,
            Amount: addonsModel.codeClanFund.Amount,
            Currency: addonsModel.codeClanFund.Currency,
            Tax: addonsModel.codeClanFund.Tax,
          }
        }

        // if (addonsModel.officeSpace.Enabled) {
        //   this.model.OfficeSpaceId = addonsModel.officeSpace.officeSpaceId
        // } else {
        //   this.model.OfficeSpaceId = undefined
        // }

        this.model.PositionId = this.position.Id
        if (this.model.OfficeLocationId == "OfficeSpace") {
          this.model.OfficeLocationId = undefined
        }
        try {
          if (!this.isJdSearch) {
            const result = (await new ClientCommitmentApi().clientCreateCommitment(this.model)).data
            this.$emit("AssignTmToPosition", result)
            store.dispatch("showSuccessMessage", "Commitment Created Successfully.")
          } else {
            const positionDetailRequest = {
              jobDescription: this.JobDescriptionByJdSearch,
              positionDetails: {
                contactVerification: this.model.Addons.ContractAddon?.ContactVerification ?? undefined,
                contractManagement: this.model.Addons.ContractAddon?.ContractManagement ?? undefined,
                daysOfWeekInOffice: this.model.DaysOfWeekInOffice ?? undefined,
                endDate: this.model.EndDate ?? undefined,
                endWorkClock: this.model.EndWorkClock ?? undefined,
                hoursPerWeek: this.model.HoursPerWeek ?? undefined,
                hoursPerWeekCasual: this.model.HoursPerWeekCasual ?? undefined,
                idVerification: this.model.Addons.ContractAddon?.IDVerification ?? undefined,
                lineManagerId: this.model.LineManagerId ?? undefined,
                locationType: this.model.LocationType ?? undefined,
                officeDaysPerWeek: this.model.OfficeDaysPerWeek ?? undefined,
                officeLocationId: this.model.OfficeLocationId ?? undefined,
                projectId: this.model.PositionId ?? undefined,
                projectLeadId: this.model.ProjectLeadId ?? undefined,
                publicHolidayZoneId: this.model.PublicHolidayZoneId ?? undefined,
                salaryCurrency: this.model.Addons.PayrollAddon?.SalaryCurrency ?? undefined,
                salaryMonthly: this.model.Addons.PayrollAddon?.SalaryInterval ?? undefined,
                seniorityLevel: this.model.Addons.PayrollAddon?.SalaryAmount ?? undefined,
                startDate: this.model.StartDate ?? undefined,
                startWorkClock: this.model.StartWorkClock ?? undefined,
                timeZone: this.model.TimeZone ?? undefined,
                virtualDesktopSpec: this.model.Addons.VirtualDesktopAddon ?? undefined,
                workingDays: this.model.WorkingDays ?? undefined,
              } as PositionDetailsModel,
            } as PositionJDAndDetailsRequestModel
            const response = await PositionService.SavePositionDetails(this.position.Id, positionDetailRequest)

            if (response) {
              this.$emit("UpdatePositionDetail", this.JobDescriptionByJdSearch)
              store.dispatch("showSuccessMessage", "Search by Job Description Submitted Successfully.")
            }
          }
        } finally {
          this.loading = false
        }
      },
      viewTmDetails(event: any) {
        this.selectedTM = event
        this.showTMDetails = true
      },
      assignTmToPosition(event: any) {
        this.isJdSearch = false
        this.showTMDetails = false
        ;(this.$refs.stepper as any).next()
      },
      closeDialog() {
        this.confirmDialog = true
        // this.$emit("OnClose");
      },
    },
    async mounted() {
      await this.fetchListOfCandidatesShortlisted()
    },
    computed: {
      getPositionSkills() {
        return this.position.Skills
      },
      getPositionJobRole() {
        return this.position.JobRole
      },
      getPostionJD() {
        return this.position.JobDescription
      },
    },
  })
</script>
<style lang="scss" scoped>
  .search-background {
    color: #000;
  }
  .close-btn {
    position: absolute;
    right: 12px;
    top: 10px;
    padding-top: 10px;
    z-index: 9999;
    color: white;
  }
</style>
