import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column ga-2"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex flex-column ga-1"
}
const _hoisted_3 = { class: "f-sub-heading c-white" }
const _hoisted_4 = { class: "f-sub-heading c-white" }
const _hoisted_5 = {
  key: 2,
  class: "c-silver-2 f-body-1 hidden-sm-and-down"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    class: _normalizeClass([_ctx.isTotal ? 'text-white pb-2' : ''])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        class: _normalizeClass({ 'total-cost-container': !_ctx.isOrderItem && !_ctx.isTotal })
      }, {
        default: _withCtx(() => [
          (!_ctx.isOrderItem)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("h4", null, "Estimated " + _toDisplayString(_ctx.isTotal ? "Total" : "") + " Monthly Cost " + _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.getPerMonthPrice), 1),
                _createElementVNode("h4", null, "(" + _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.getPerHourPrice) + " per work hour)", 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, "Estimated Monthly Cost " + _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.getPerMonthPrice), 1),
                _createElementVNode("span", _hoisted_4, "(" + _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.getPerHourPrice) + " per work hour)", 1)
              ])),
          (!_ctx.isOrderItem && !_ctx.isTotal)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Costs are estimated based on usage time. All smart workbench virtual desktops are dedicated 24/7, but you only get charged for the hours you use. "))
            : _createCommentVNode("", true),
          (!_ctx.isOrderItem && !_ctx.isTotal)
            ? (_openBlock(), _createBlock(_component_v_tooltip, {
                key: 3,
                text: _ctx.getPriceTooltip(),
                "max-width": "300"
              }, {
                activator: _withCtx(({ props }) => [
                  _createElementVNode("span", _mergeProps(props, { class: "f-body-1 hidden-sm-and-down" }), "More Information on transparent pricing", 16)
                ]),
                _: 1
              }, 8, ["text"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["class"]))
}