<template>
  <v-card class="b-eerie-black">
    <v-card-text>
      <CardTitle title="Timesheet Processing" />
      <v-form ref="frmTimesheet" v-model="timesheetValid" class="">
        <v-row class="align-center">
          <v-col cols="12" sm="6" md="4">
            <AutoCompleteField
              :rules="[rules.required]"
              label="Project Lead"
              :items="projectLeads"
              item-title="Name"
              item-value="id"
              v-model="model.CommitmentProjectLeadId"
              append-icon="mdi-plus" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <AutoCompleteField
              :rules="[!isClient || rules.required]"
              label="Portfolio Manager"
              :items="portfolioManagers"
              item-title="Name"
              item-value="id"
              v-model="model.CommitmentPortfolioManagerId"
              append-icon="mdi-plus"
              :disabled="!isClient" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <AutoCompleteField :rules="[rules.required]" label="Timezone" v-model="model.CommitmentTimeZone" :items="timeZones" outlined :return-object="false" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <SelectField
              :rules="[rules.required]"
              label="Public Holiday Zone"
              :items="publicHolidayZones"
              item-title="Name"
              item-value="id"
              v-model="model.CommitmentPublicHolidayZoneId" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <AutoCompleteField
              :rules="[rules.required]"
              label="Days of Week"
              v-model="model.CommitmentWorkingDays"
              :items="workingDays"
              multiple
              outlined
              @update:modelValue="DayOfWeekChanged"
              :return-object="false" />
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <AutoCompleteField :rules="[rules.required]" label="From" v-model="model.CommitmentFrom" :items="hoursInDay" />
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <AutoCompleteField :rules="[rules.required]" label="To" v-model="model.CommitmentTo" :items="hoursInDay" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <NumberField
              :disabled="model.CommitmentHoursPerWeekRadio"
              label="Work hours per week"
              :rules="[model.CommitmentHoursPerWeekRadio || rules.minNumber(1) || rules.required]"
              v-model="model.CommitmentHoursPerWeek"
              :min="1" />
          </v-col>
          <v-col cols="12" sm="6" md="4" class="align-content-center">
            <v-checkbox label="Casual" hide-details v-model="model.CommitmentHoursPerWeekRadio" @change="CasualChange" />
          </v-col>
        </v-row>
      </v-form>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="d-flex align-end justify-center ga-3">
              <v-btn class="secondary_btn" @click="cancel">Cancel</v-btn>
              <v-btn class="primary_btn" @click="save">Add</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
  import { defineComponent, toRaw } from "vue"
  import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue"
  import rules from "shared-components/src/utils/validations"
  import Details from "shared-components/src/models/Details"
  import moment from "moment"
  import store from "@/store"
  import { CountryModel } from "shared-components/src/services/openApi/api"
  import Project from "shared-components/src/models/Project"
  import PriceCalculator from "./PriceCalculator.vue"
  import CardTitle from "shared-components/src/components/CardTitle.vue"

  var momentTz = require("moment-timezone")

  export default defineComponent({
    props: ["cpuValues", "ramValues", "diskValues", "model", "projectLeads", "portfolioManagers", "publicHolidayZones"],
    components: { VirtualDesktopAddon, PriceCalculator, CardTitle },
    data() {
      return {
        lastEmailValidated: "",
        projects: [] as Project[],
        stepState: 1,
        vdValid: false,
        timesheetValid: false,
        loading: false,
        rules,
        timeZones: [] as Array<{ title: string; value: string }>,
        tmDetailsDisabled: true,
        countries: [] as CountryModel[],
        details: [] as Details[],
        hoursInDay: ["Flexible"],
        workingDays: [
          { title: "Flexible", value: "Flexible", props: { disabled: false } },
          { title: "SUN", value: "SUN", props: { disabled: false } },
          { title: "MON", value: "MON", props: { disabled: false } },
          { title: "TUE", value: "TUE", props: { disabled: false } },
          { title: "WED", value: "WED", props: { disabled: false } },
          { title: "THR", value: "THR", props: { disabled: false } },
          { title: "FRI", value: "FRI", props: { disabled: false } },
          { title: "SAT", value: "SAT", props: { disabled: false } },
        ],
      }
    },
    async mounted() {
      this.fillHoursInDay()
      this.loadTimeZones()
    },
    methods: {
      cancel() {
        this.$emit("cancel")
      },
      async save() {
        const isValid = (await (this.$refs.frmTimesheet as any).validate()).valid
        if (isValid) {
          this.$emit("save")
        }
      },
      CasualChange() {
        this.model.CommitmentHoursPerWeek = undefined
      },
      fillHoursInDay() {
        this.hoursInDay = ["Flexible"]
        this.hoursInDay = this.hoursInDay.concat(...Array.from(Array(24), (_, hour) => [moment({ hour }).format("h:mm A"), moment({ hour, minute: 30 }).format("h:mm A")]))
      },
      DayOfWeekChanged() {
        if (this.model.CommitmentWorkingDays?.includes("Flexible")) {
          this.model.CommitmentWorkingDays = ["Flexible"]
          this.workingDays.forEach((item: any) => (item.title != "Flexible" ? (item.props.disabled = true) : (item.props.disabled = false)))
        } else {
          this.workingDays.forEach((item: any) => (item.props.disabled = false))
        }
      },
      loadTimeZones() {
        var timeZones = momentTz.tz.names()

        for (var i in timeZones) {
          this.timeZones.push({
            title: timeZones[i] + " (GMT" + momentTz.tz(timeZones[i]).format("Z") + ")",
            value: timeZones[i],
          })
        }
      },
    },
    computed: {
      getTodayDate() {
        return new Date().toDateString()
      },
      getMinDate() {
        if (this.model.CommitmentStartDate && this.model.CommitmentEndDate && this.model.CommitmentStartDate > this.model.CommitmentEndDate) {
          this.model.CommitmentEndDate = this.model.CommitmentStartDate
        }
        return !this.model.CommitmentStartDate || this.model.CommitmentStartDate == "" ? this.getTodayDate : this.model.CommitmentStartDate
      },
      isClient(): boolean {
        return store.getters.userIsClient
      },
      getJobRoles() {
        return toRaw(this.details).filter((item: any) => item.Type == "JobRole")
      },
    },
  })
</script>
<style scoped lang="scss">
  .setup-complete-container {
    padding: 24px;
  }
</style>
