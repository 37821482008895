<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <AutoCompleteField label="Select" :items="teammemberList" item-title="FullName" item-value="Id" v-model="teamMembersSelected" multiple chips return-object />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="cancelSelection" class="secondary_btn">Cancel</v-btn>
      <v-btn @click="saveAndClose" class="primary_btn">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import { RoadMapTalentModel } from "shared-components/src/models/RoadMapModel"
  import { TeammemberWithSummaryDetails } from "shared-components/src/models/Teammember"
  import { defineComponent } from "vue"

  export default defineComponent({
    props: {
      talents: {
        type: Array as () => RoadMapTalentModel[],
      },
      teamMembers: {
        type: Array as () => TeammemberWithSummaryDetails[],
      },
    },
    data() {
      return {
        talentsPopupVisiblity: false,
        teamMembersSelected: [],
      }
    },
    methods: {
      cancelSelection() {
        this.$emit("cancelSelection")
      },
      saveAndClose() {
        this.$emit("addTalentsToRoadMap", this.teamMembersSelected)
        this.teamMembersSelected = []
        this.cancelSelection()
      },
    },
    computed: {
      teammemberList() {
        const talentIds = this.talents?.map((item: any) => item.Id)
        return this.teamMembers
          ? this.teamMembers
              .map((item: any) => {
                return { Id: item.Id, FullName: item.FullName }
              })
              .filter((item: any) => !talentIds?.includes(item.Id))
          : []
      },
    },
  })
</script>
