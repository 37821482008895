import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_NumberField = _resolveComponent("NumberField")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "container" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmMain",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": "",
            class: "form-inputs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        modelValue: _ctx.questionModel.Title,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.questionModel.Title) = $event)),
                        label: "Title",
                        rules: [_ctx.rules.required]
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        modelValue: _ctx.selectedQuestionTechnology,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedQuestionTechnology) = $event)),
                        label: "Technology",
                        "prepend-icon": "mdi-application-brackets-outline",
                        items: _ctx.technologies,
                        "item-title": "Text",
                        "item-value": "id",
                        clearable: "",
                        multiple: ""
                      }, null, 8, ["modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SelectField, {
                        modelValue: _ctx.questionModel.Level,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.questionModel.Level) = $event)),
                        label: "Level",
                        "prepend-icon": "mdi-arrow-up-bold-outline",
                        items: ['Easy', 'Medium', 'Hard'],
                        clearable: "",
                        rules: [_ctx.rules.required]
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SelectField, {
                        modelValue: _ctx.selectedEditors,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedEditors) = $event)),
                        label: "Editor",
                        "prepend-icon": "mdi-file-code-outline",
                        items: _ctx.editors,
                        "item-title": "Value",
                        "item-value": "Id",
                        multiple: "",
                        clearable: ""
                      }, null, 8, ["modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NumberField, {
                        modelValue: _ctx.questionModel.Duration,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.questionModel.Duration) = $event)),
                        label: "Duration/mins",
                        "prepend-icon": "mdi-clock-time-twelve-outline",
                        rules: [_ctx.rules.required]
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextAreaField, {
                        modelValue: _ctx.questionModel.Question,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.questionModel.Question) = $event)),
                        label: "Questions",
                        rows: "4",
                        "prepend-icon": "mdi-tooltip-question-outline",
                        rules: [_ctx.rules.required],
                        clearable: ""
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextAreaField, {
                        modelValue: _ctx.questionModel.Answer,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.questionModel.Answer) = $event)),
                        label: "Answer",
                        rows: "3",
                        "prepend-icon": "mdi-tooltip-question-outline",
                        clearable: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "mb-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.addEvaluation())),
                        "prepend-icon": "mdi-plus",
                        class: "secondary_btn"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("Add Evaluation")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.evaluatons, (evaluation, index) => {
                return (_openBlock(), _createBlock(_component_v_row, {
                  key: index,
                  class: "evaluation-list align-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "8" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextAreaField, {
                          modelValue: evaluation.Criteria,
                          "onUpdate:modelValue": ($event: any) => ((evaluation.Criteria) = $event),
                          label: "Evaluation",
                          rows: "1",
                          "prepend-icon": "mdi-pencil",
                          rules: [_ctx.rules.required],
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_col, { cols: "3" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SelectField, {
                          modelValue: evaluation.Point,
                          "onUpdate:modelValue": ($event: any) => ((evaluation.Point) = $event),
                          label: "Point",
                          "prepend-icon": "mdi-pen-plus",
                          items: _ctx.pointItems,
                          rules: [_ctx.rules.required]
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "rules"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_col, { cols: "1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          onClick: ($event: any) => (_ctx.deleteEvaluation(index)),
                          icon: "mdi-delete",
                          variant: "text",
                          label: "Delete"
                        }, null, 8, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "mt-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            onClick: _ctx.close,
            class: "secondary_btn"
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("Close")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            onClick: _ctx.save,
            class: "primary_btn"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.question != null ? "Save" : "Add"), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}