import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/timesheet-processing.svg'
import _imports_1 from '@/assets/images/code-integration.svg'


const _hoisted_1 = { class: "d-flex flex-column ga-2 align-center" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  class: "service-item-image"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  class: "service-item-image"
}
const _hoisted_4 = { class: "f-body-1 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.title)
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        class: "border-white-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
                (_ctx.type == 'TimesheetProcessing')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_2))
                  : _createCommentVNode("", true),
                (_ctx.type == 'CodeScan')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                  : _createCommentVNode("", true),
                _createElementVNode("h4", null, "+ $" + _toDisplayString(_ctx.fee) + " per hour", 1),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.description), 1),
                _createVNode(_component_v_btn, {
                  class: "tertiary_btn",
                  onClick: _ctx.add
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Add")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}