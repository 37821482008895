<template>
  <div>
    <v-form ref="mainForm" :loading="loading" :disabled="loading">
      <v-card flat>
        <v-card-text>
          <div class="d-flex ga-2 justify-end">
            <SelectField :rules="[rules.required]" :items="currencies" v-model="selectedCurrency" label="Currency" placeholder="Currency" @update:modelValue="updateCurrencies" />
            <div class="tax-input">
              <NumberField :rules="[rules.required]" v-model="selectedTax" min="0" label="Tax" @update:modelValue="updateTaxes" />
            </div>
          </div>
          <v-expansion-panels v-model="panelOpenState">
            <v-expansion-panel hide-actions>
              <v-expansion-panel-title :class="{ active: model.timeSheetProcessing.Enabled }">
                <template v-slot:default="{ expanded }">
                  <div class="d-flex ga-2 align-center justify-space-between w-100">
                    <div class="d-flex ga-2 align-center">
                      <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      <v-checkbox @click.stop v-model="model.timeSheetProcessing.Enabled" label="Timesheet Processing" hide-details />
                    </div>
                    <div class="d-flex ga-2 align-center">
                      <span>{{ selectedCurrency }} {{ model.timeSheetProcessing.Amount }} P/H</span>
                      <v-icon>mdi-information</v-icon>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <TimeSheetProcessingAddon :timeSheetProcessing="model.timeSheetProcessing" />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel hide-actions>
              <v-expansion-panel-title :class="{ active: model.virtualDesktop.Enabled }">
                <template v-slot:default="{ expanded }">
                  <div class="d-flex ga-2 align-center justify-space-between w-100">
                    <div class="d-flex ga-2 align-center">
                      <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      <v-checkbox @click.stop v-model="model.virtualDesktop.Enabled" label="Virtual Desktop" hide-details />
                    </div>
                    <div class="d-flex ga-2 align-center">
                      <span>{{ selectedCurrency }} {{ model.virtualDesktop.Amount }} P/H</span>
                      <v-icon>mdi-information</v-icon>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <VirtualDesktopAddon :isAddon="true" :virtualDesktopSpec="model.virtualDesktop" :diskValues="diskValues" :cpuValues="cpuValues" :ramValues="ramValues" />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel hide-actions :disabled="!model.virtualDesktop.Enabled">
              <v-expansion-panel-title :class="{ active: model.codeScan.Enabled }">
                <template v-slot:default="{ expanded }">
                  <div class="d-flex ga-2 align-center justify-space-between w-100">
                    <div class="d-flex ga-2 align-center">
                      <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      <v-checkbox @click.stop v-model="model.codeScan.Enabled" label="Code Scan" hide-details />
                    </div>
                    <div class="d-flex ga-2 align-center">
                      <span>{{ selectedCurrency }} {{ model.codeScan.Amount }} P/H</span>
                      <v-icon>mdi-information</v-icon>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <CodeScanAddon :codeScan="model.codeScan" />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel hide-actions>
              <v-expansion-panel-title :class="{ active: model.payRoll.Enabled }">
                <template v-slot:default="{ expanded }">
                  <div class="d-flex ga-2 align-center justify-space-between w-100">
                    <div class="d-flex ga-2 align-center">
                      <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      <v-checkbox @click.stop v-model="model.payRoll.Enabled" label="Payroll" hide-details />
                    </div>
                    <div class="d-flex ga-2 align-center">
                      <div class="d-flex flex-column ga-2 align-center">
                        <span>{{ selectedCurrency }} {{ model.payRoll.PayrollAmount }} P/H</span>
                        <span>
                          {{ model.payRoll.SalaryCurrency || selectedCurrency }} {{ model.payRoll.SalaryAmount }}
                          {{ model.payRoll.SalaryInterval == "Hourly" ? "P/H" : model.payRoll.SalaryInterval == "Monthly" ? "P/M" : "P/H" }}
                        </span>
                      </div>

                      <v-dialog max-width="500">
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props">mdi-information</v-icon>
                        </template>

                        <template v-slot:default="{ isActive }">
                          <v-card>
                            <v-card-text>
                              <div class="d-flex flex-column">
                                <h5>Benefits Management:</h5>
                                <span class="ml-2">Administering employee benefits programs such as loans.</span>
                                <span class="ml-2">Ensuring employees are aware of and utilizing available benefits.</span>
                                <h5>Calculating Salaries and Wages:</h5>
                                <span class="ml-2">Calculate base salary.</span>
                                <span class="ml-2">Apply mandatory and voluntary deductions such as insurance, and loan repayments.</span>
                                <h5>Processing Payroll Payments:</h5>
                                <span class="ml-2">Transfer salaries electronically to employees' bank accounts.</span>
                                <h5>Addressing Queries and Complaints:</h5>
                                <span class="ml-2">Respond to employee queries and complaints regarding payroll.</span>
                                <span class="ml-2">Resolve issues and discrepancies related to salary payments.</span>
                                <h5>Reporting:</h5>
                                <span class="ml-2">Prepare monthly, quarterly, and annual payroll reports.</span>
                              </div>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn class="primary_btn" text="Close" @click="isActive.value = false"></v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <PayrollAddon :payRoll="model.payRoll" />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel hide-actions>
              <v-expansion-panel-title :class="{ active: model.contract.Enabled }">
                <template v-slot:default="{ expanded }">
                  <div class="d-flex ga-2 align-center justify-space-between w-100">
                    <div class="d-flex ga-2 align-center">
                      <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      <v-checkbox @click.stop v-model="model.contract.Enabled" label="Contract" hide-details />
                    </div>
                    <div class="d-flex ga-2 align-center">
                      <span>{{ selectedCurrency }} {{ model.contract.Amount }} P/H</span>
                      <v-dialog max-width="500" scrollable>
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props">mdi-information</v-icon>
                        </template>

                        <template v-slot:default="{ isActive }">
                          <v-card>
                            <v-card-text>
                              <div class="d-flex flex-column">
                                <h5>Document and Credential Verification:</h5>
                                <span class="ml-2">Collect and verify educational certificates, work experience documents, and identification proofs.</span>
                                <span class="ml-2">Ensure the authenticity of documents and the accuracy of the information provided.</span>
                                <span class="ml-2">Address, phone and email verification of TM.</span>
                                <h5>Liaison with Relevant Authorities:</h5>
                                <span class="ml-2">
                                  Communicate with educational institutions, previous employers, and other relevant authorities to verify information and records.
                                </span>
                                <span class="ml-2">Perform background checks and criminal record investigations (if necessary).</span>
                                <h5>Contract Preparation and Drafting:</h5>
                                <span class="ml-2">Prepare and draft employment contracts based on legal standards and organizational policies.</span>
                                <span class="ml-2">Consult with the legal team to ensure the contract complies with labor laws and regulations.</span>
                                <h5>Negotiation with Candidates:</h5>
                                <span class="ml-2">Conduct final negotiations with candidates regarding contract terms, benefits, and obligations.</span>
                                <span class="ml-2">Address candidates' questions and clarify any ambiguities regarding contract clauses.</span>
                                <h5>Contract Signing:</h5>
                                <span class="ml-2">Schedule contract signing meetings with the candidate and organizational representatives.</span>
                                <span class="ml-2">Collect necessary signatures and complete the contract signing process.</span>
                                <h5>Onboarding and Orientation:</h5>
                                <span class="ml-2">Provide initial training and familiarize the candidate with the organization's culture and policies.</span>
                                <span class="ml-2">Introduce the candidate to relevant teams and departments.</span>
                                <h5>Record Keeping and Documentation:</h5>
                                <span class="ml-2">Maintain and organize all documents and contracts related to employees.</span>
                                <span class="ml-2">Manage and update personnel files regularly.</span>
                                <h5>Compliance Monitoring:</h5>
                                <span class="ml-2">Ensure all legal and organizational regulations are followed during the verification and contract signing process.</span>
                                <span class="ml-2">Implement internal policies and procedures to ensure compliance with standards.</span>
                              </div>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn class="primary_btn" text="Close" @click="isActive.value = false"></v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <ContractAddon :contract="model.contract" />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <!-- <v-expansion-panel :disabled="commitmentModel.LocationType == 'Remote' || commitmentModel.OfficeLocationId != 'OfficeSpace'">
              <v-expansion-panel-title :class="{ active: model.officeSpace.Enabled }">
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">Office Space</v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <OfficeSpaceAddon :calculateOfficeSpace="calculateOfficeSpace" :officeSpace="model.officeSpace" :officeSpaces="officeSpaces" />
              </v-expansion-panel-text>
            </v-expansion-panel> -->
            <v-expansion-panel hide-actions>
              <v-expansion-panel-title :class="{ active: model.lineManager.Enabled }">
                <template v-slot:default="{ expanded }">
                  <div class="d-flex ga-2 align-center justify-space-between w-100">
                    <div class="d-flex ga-2 align-center">
                      <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      <v-checkbox @click.stop v-model="model.lineManager.Enabled" label="Line Manager" hide-details />
                    </div>
                    <div class="d-flex ga-2 align-center">
                      <span>{{ selectedCurrency }} {{ model.lineManager.Amount }} P/H</span>
                      <v-dialog max-width="500" scrollable>
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props">mdi-information</v-icon>
                        </template>

                        <template v-slot:default="{ isActive }">
                          <v-card>
                            <v-card-text>
                              <div class="d-flex flex-column">
                                <h5>Planning and Resource Allocation:</h5>
                                <span class="ml-2">Allocating resources and tasks to team members based on project priorities and needs.</span>
                                <span class="ml-2">Planning and managing schedules to ensure goals and deadlines are met.</span>
                                <h5>Communication and Coordination:</h5>
                                <span class="ml-2">Establishing effective communication with other departments and managers to coordinate activities and projects.</span>
                                <span class="ml-2">Facilitating internal team communications and creating a space for discussion and problem-solving.</span>
                                <h5>Performance Management:</h5>
                                <span class="ml-2">Setting performance goals and standards for team members.</span>
                                <span class="ml-2">Monitoring the performance and productivity of employees and taking corrective actions as needed.</span>
                                <h5>Problem Solving and Conflict Resolution:</h5>
                                <span class="ml-2">Managing and resolving internal team problems and conflicts professionally and effectively.</span>
                                <span class="ml-2">Providing appropriate solutions to enhance collaboration and reduce tensions.</span>
                                <h5>Employee Development and Training:</h5>
                                <span class="ml-2">Identifying employees' training and development needs and providing training opportunities.</span>
                                <span class="ml-2">Assisting in the professional growth and development of employees through counseling and individual development planning.</span>
                                <h5>Budget and Expense Management:</h5>
                                <span class="ml-2">Monitoring the budget and expenses related to the team and projects.</span>
                                <span class="ml-2">Ensuring optimal use of financial resources and achievement of financial goals.</span>
                                <h5>Evaluation and Reporting:</h5>
                                <span class="ml-2">Preparing periodic reports on team performance and project progress.</span>
                                <span class="ml-2">Presenting reports and findings to senior managers and stakeholders.</span>
                                <h5>Ensuring Compliance with Laws and Regulations:</h5>
                                <span class="ml-2">Ensuring compliance with organizational and governmental laws and regulations by the team.</span>
                                <span class="ml-2">Monitoring the implementation of organizational policies and processes within the team.</span>
                              </div>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn class="primary_btn" text="Close" @click="isActive.value = false"></v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <LineManagerAddon :lineManagerAddon="model.lineManager" :lineManagers="lineManagers" />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <!-- <v-expansion-panel>
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">Traning Roadmaps</v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
            </v-expansion-panel> -->
            <v-expansion-panel hide-actions>
              <v-expansion-panel-title :class="{ active: model.tdm.Enabled }">
                <template v-slot:default="{ expanded }">
                  <div class="d-flex ga-2 align-center justify-space-between w-100">
                    <div class="d-flex ga-2 align-center">
                      <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      <v-checkbox @click.stop v-model="model.tdm.Enabled" label="Talent Development Manager" hide-details />
                    </div>
                    <div class="d-flex ga-2 align-center">
                      <span>{{ selectedCurrency }} {{ model.tdm.Amount }} P/H</span>
                      <v-icon>mdi-information</v-icon>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <TDMAddon :tdmModel="model.tdm" :tdms="tdms" />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel hide-actions>
              <v-expansion-panel-title :class="{ active: model.codeClanFund.Enabled }">
                <template v-slot:default="{ expanded }">
                  <div class="d-flex ga-2 align-center justify-space-between w-100">
                    <div class="d-flex ga-2 align-center">
                      <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      <v-checkbox @click.stop v-model="model.codeClanFund.Enabled" label="Code Clan Fund" hide-details />
                    </div>
                    <div class="d-flex ga-2 align-center">
                      <span>{{ selectedCurrency }} {{ model.codeClanFund.Amount }} P/H</span>
                      <v-icon>mdi-information</v-icon>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <CodeClanFundAddon :codeClanFund="model.codeClanFund" />
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="mt-2 flex flex-column text-right">
            <div>
              Total:
              <span>{{ selectedCurrency }} {{ getTotalAmount }} P/H</span>
            </div>

            <span v-if="model.payRoll.Enabled && (selectedCurrency != model.payRoll.SalaryCurrency || (model.payRoll.SalaryInterval && model.payRoll.SalaryInterval != 'Hourly'))">
              {{ model.payRoll.SalaryCurrency }} {{ model.payRoll.SalaryAmount ?? 0 }} {{ model.payRoll.SalaryInterval == "Hourly" ? "P/H" : "P/M" }}
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
    <div class="actionBtns mt-3">
      <v-btn class="secondary_btn" @click="backStep" :loading="loading">Previous</v-btn>
      <v-btn class="primary_btn" @click="nextStep" :loading="loading">Save</v-btn>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import moment from "moment"
  import store from "@/store"
  import PayrollAddon from "shared-components/src/components/Commitment/Addons/PayrollAddon.vue"
  import ContractAddon from "shared-components/src/components/Commitment/Addons/ContractAddon.vue"
  import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue"
  import LineManagerAddon from "shared-components/src/components/Commitment/Addons/LineManagerAddon.vue"
  import OfficeSpaceAddon from "shared-components/src/components/Commitment/Addons/OfficeSpaceAddon.vue"
  import TimeSheetProcessingAddon from "shared-components/src/components/Commitment/Addons/TimeSheetProcessingAddon.vue"
  import CodeScanAddon from "shared-components/src/components/Commitment/Addons/CodeScanAddon.vue"
  import TDMAddon from "shared-components/src/components/Commitment/Addons/TDMAddon.vue"
  import CodeClanFundAddon from "shared-components/src/components/Commitment/Addons/CodeClanFundAddon.vue"

  import rules from "shared-components/src/utils/validations"

  export default defineComponent({
    components: {
      PayrollAddon,
      ContractAddon,
      VirtualDesktopAddon,
      LineManagerAddon,
      OfficeSpaceAddon,
      TimeSheetProcessingAddon,
      CodeScanAddon,
      TDMAddon,
      CodeClanFundAddon,
    },
    props: ["loading", "lineManagers", "officeSpaces", "commitmentModel", "tdms"],
    data() {
      return {
        panelOpenState: null as null | number,
        selectedCurrency: "AUD",
        selectedTax: 0,
        currencies: ["AUD", "PHP", "MYR", "USD", "SGD"],
        confirmDialog: false,
        rules,
        locations: ["Melbourne - Australia", "Sydney - Australia", "Manila - Philippines", "Kuala Lumpur - Malaysia", "Tehran - Iran"],
        policies: ["Windows 10 Enterprise CIS"],
        levels: ["L1", "L2"],
        salaries: ["Hourly", "Monthly"],
        operatingSystems: ["Windows 10", "Windows 11", "Ubuntu 22", "Ubuntu 24", "Debian 9", "Debian 10", "CentOS 8"],
        ramValues: {
          0: "8",
          1: "16",
          2: "24",
          3: "32",
        },
        cpuValues: {
          0: "6",
          1: "8",
          2: "10",
        },
        diskValues: {
          0: "128",
          1: "256",
          2: "384",
          3: "512",
        },
        model: {
          virtualDesktop: {
            AdditionalSecurityBL: false,
            AdditionalSecurityNG: false,
            Cpu: 0,
            Disk: 0,
            Enabled: false,
            Level: null as string | null,
            Location: null as string | null,
            OperatingSystem: null as string | null,
            Policy: null as string | null,
            Ram: 0,
            Amount: 0,
            Currency: null as string | null,
            Tax: 0,
          },
          lineManager: {
            Enabled: false,
            LineManagerId: null as string | null,
            Amount: 0,
            Currency: null as string | null,
            Tax: 0,
          },
          payRoll: {
            Enabled: false,
            SalaryAmount: 0,
            SalaryCurrency: null as string | null,
            SalaryInterval: null as string | null,
            SalaryTax: 0,
            PayrollAmount: 0,
            PayrollCurrency: null as string | null,
            PayrollTax: 0,
          },
          contract: {
            Enabled: false,
            ContractManagement: false,
            ContactVerification: false,
            IDVerification: false,
            Amount: 0,
            Currency: null as string | null,
            Tax: 0,
          },
          officeSpace: {
            Enabled: false,
            officeSpaceId: null as string | null,
            Amount: 0,
            Currency: null as string | null,
            Tax: 0,
          },
          timeSheetProcessing: {
            Enabled: false,
            Amount: 0,
            Currency: null as string | null,
            Tax: 0,
          },
          tdm: {
            Enabled: false,
            TdmId: null as string | null,
            Amount: 0,
            Currency: null as string | null,
            Tax: 0,
          },
          codeScan: {
            Enabled: false,
            Amount: 0,
            Currency: null as string | null,
            Tax: 0,
          },
          codeClanFund: {
            Enabled: false,
            Amount: 0,
            Currency: null as string | null,
            Tax: 0,
          },
        },
      }
    },
    mounted() {
      if (this.commitmentModel.LocationType == "Remote" || this.commitmentModel.OfficeLocationId != "OfficeSpace") {
        this.model.officeSpace.Enabled = false
      } else {
        this.model.officeSpace.Enabled = true
      }
      this.updateCurrencies()
      this.updateTaxes()
    },
    methods: {
      backStep() {
        this.$emit("BackStep")
      },
      async nextStep() {
        const isValid = await (this.$refs.mainForm as any).validate()
        if (isValid.valid) {
          const model = JSON.parse(JSON.stringify(this.model))
          if (model.virtualDesktop.Enabled) {
            model.virtualDesktop.Cpu = Number(Object.values(this.cpuValues)[this.model.virtualDesktop.Cpu])
            model.virtualDesktop.Ram = Number(Object.values(this.ramValues)[this.model.virtualDesktop.Ram])
            model.virtualDesktop.Disk = Number(Object.values(this.diskValues)[this.model.virtualDesktop.Disk])
          }
          this.$emit("NextStep", model)
        }
      },
      updateCurrencies() {
        if (!this.model.payRoll.SalaryCurrency || this.model.payRoll.SalaryCurrency == "") {
          this.model.payRoll.SalaryCurrency = this.selectedCurrency
        }

        this.model.payRoll.PayrollCurrency = this.selectedCurrency
        this.model.codeClanFund.Currency = this.selectedCurrency
        this.model.codeScan.Currency = this.selectedCurrency
        this.model.officeSpace.Currency = this.selectedCurrency
        this.model.contract.Currency = this.selectedCurrency
        this.model.virtualDesktop.Currency = this.selectedCurrency
        this.model.tdm.Currency = this.selectedCurrency
        this.model.lineManager.Currency = this.selectedCurrency
        this.model.timeSheetProcessing.Currency = this.selectedCurrency
      },
      updateTaxes() {
        this.model.payRoll.PayrollTax = this.selectedTax
        this.model.codeClanFund.Tax = this.selectedTax
        this.model.officeSpace.Tax = this.selectedTax
        this.model.codeScan.Tax = this.selectedTax
        this.model.contract.Tax = this.selectedTax
        this.model.virtualDesktop.Tax = this.selectedTax
        this.model.tdm.Tax = this.selectedTax
        this.model.lineManager.Tax = this.selectedTax
        this.model.timeSheetProcessing.Tax = this.selectedTax
      },
    },
    watch: {
      commitmentModel: {
        handler(newVal) {
          if (newVal.LocationType == "Remote" || newVal.OfficeLocationId != "OfficeSpace") {
            if (this.model.officeSpace.Enabled == true) {
              store.dispatch("showWarningMessage", "Office space will be disabled because it is not supported by your location type")
            }
            this.model.officeSpace.Enabled = false
            this.model.officeSpace.officeSpaceId = null
          } else {
            this.model.officeSpace.Enabled = true
          }
        },
        deep: true,
      },
      "model.timeSheetProcessing.Enabled": {
        handler(newVal) {
          if (newVal) {
            this.panelOpenState = 0
          }
        },
      },
      "model.virtualDesktop.Enabled": {
        handler(newVal) {
          if (!newVal) {
            this.model.codeScan.Enabled = false
            this.model.codeScan.Amount = 0
          } else {
            this.panelOpenState = 1
          }
        },
      },
      "model.codeScan.Enabled": {
        handler(newVal) {
          if (newVal) {
            this.panelOpenState = 2
          }
        },
      },
      "model.payRoll.Enabled": {
        handler(newVal) {
          if (newVal) {
            this.panelOpenState = 3
          }
        },
      },
      "model.contract.Enabled": {
        handler(newVal) {
          if (newVal) {
            this.panelOpenState = 4
          }
        },
      },
      "model.lineManager.Enabled": {
        handler(newVal) {
          if (newVal) {
            this.panelOpenState = 5
          }
        },
      },
      "model.tdm.Enabled": {
        handler(newVal) {
          if (newVal) {
            this.panelOpenState = 6
          }
        },
      },
      "model.codeClanFund.Enabled": {
        handler(newVal) {
          if (newVal) {
            this.panelOpenState = 7
          }
        },
      },
    },
    computed: {
      getTotalAmount() {
        const tmAmount = this.model.timeSheetProcessing.Amount ?? 0
        const csAmount = this.model.codeScan.Amount ?? 0
        const saAmount = this.model.payRoll.SalaryCurrency == this.selectedCurrency && this.model.payRoll.SalaryInterval == "Hourly" ? this.model.payRoll.SalaryAmount : 0
        const prAmount = this.model.payRoll.PayrollAmount ?? 0
        const ccfAmount = this.model.codeClanFund.Amount ?? 0
        const coAmount = this.model.contract.Amount ?? 0
        const vmAmount = this.model.virtualDesktop.Amount ?? 0
        const lmAmount = this.model.lineManager.Amount ?? 0
        const tdmAmount = this.model.tdm.Amount ?? 0
        return (
          Number(tmAmount) +
          Number(csAmount) +
          Number(prAmount) +
          Number(saAmount) +
          Number(coAmount) +
          Number(vmAmount) +
          Number(lmAmount) +
          Number(tdmAmount) +
          Number(ccfAmount)
        ).toFixed(2)
      },
      getPayrollAmount() {
        if (this.model.payRoll.SalaryCurrency != this.selectedCurrency) {
          return this.model.payRoll.SalaryAmount
        }
        return 0
      },
      calculateOfficeSpace() {
        let message = "You Selected"
        if (this.commitmentModel.LocationType == "OnSite") {
          if (
            this.commitmentModel.StartWorkClock == "Flexible" ||
            this.commitmentModel.EndWorkClock == "Flexible" ||
            (this.commitmentModel.WokingDays && this.commitmentModel.WokingDays.includes("Flexible"))
          ) {
            return false
          }
          const startTime = moment(this.commitmentModel.StartWorkClock, "h:mm A")
          const endTime = moment(this.commitmentModel.EndWorkClock, "h:mm A")
          const duration = moment.duration(endTime.diff(startTime))
          message = `${message} ${this.commitmentModel.WokingDays.length} days per week (${this.commitmentModel.WokingDays.join(",")}) and 
          ${duration.asHours()} Hours per day`
          return message
        } else if (this.commitmentModel.LocationType == "Hybrid") {
          if (this.commitmentModel.StartWorkClock == "Flexible" || this.commitmentModel.EndWorkClock == "Flexible" || this.commitmentModel.OfficeDaysPerWeek == "Flexible") {
            return false
          }
          const startTime = moment(this.commitmentModel.StartWorkClock, "h:mm A")
          const endTime = moment(this.commitmentModel.EndWorkClock, "h:mm A")
          const duration = moment.duration(endTime.diff(startTime))
          message = `${message} ${this.commitmentModel.OfficeDaysPerWeek} days per week in these days (${this.commitmentModel.DaysOfWeekInOffice.join(
            ","
          )}) and ${duration.asHours()} Hours per day`
          return message
        }
      },
    },
  })
</script>
<style lang="scss" scoped>
  .actionBtns {
    display: flex;
    justify-content: space-between;
  }
  .active {
    background-color: $c_emerald;
    color: $c_dark_1;
  }
  .tax-input {
    width: 100px !important;
  }
</style>
