import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex ga-2 justify-end" }
const _hoisted_2 = { class: "tax-input" }
const _hoisted_3 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_4 = { class: "d-flex ga-2 align-center" }
const _hoisted_5 = { class: "d-flex ga-2 align-center" }
const _hoisted_6 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_7 = { class: "d-flex ga-2 align-center" }
const _hoisted_8 = { class: "d-flex ga-2 align-center" }
const _hoisted_9 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_10 = { class: "d-flex ga-2 align-center" }
const _hoisted_11 = { class: "d-flex ga-2 align-center" }
const _hoisted_12 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_13 = { class: "d-flex ga-2 align-center" }
const _hoisted_14 = { class: "d-flex ga-2 align-center" }
const _hoisted_15 = { class: "d-flex flex-column ga-2 align-center" }
const _hoisted_16 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_17 = { class: "d-flex ga-2 align-center" }
const _hoisted_18 = { class: "d-flex ga-2 align-center" }
const _hoisted_19 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_20 = { class: "d-flex ga-2 align-center" }
const _hoisted_21 = { class: "d-flex ga-2 align-center" }
const _hoisted_22 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_23 = { class: "d-flex ga-2 align-center" }
const _hoisted_24 = { class: "d-flex ga-2 align-center" }
const _hoisted_25 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_26 = { class: "d-flex ga-2 align-center" }
const _hoisted_27 = { class: "d-flex ga-2 align-center" }
const _hoisted_28 = { class: "mt-2 flex flex-column text-right" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "actionBtns mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_NumberField = _resolveComponent("NumberField")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_TimeSheetProcessingAddon = _resolveComponent("TimeSheetProcessingAddon")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_VirtualDesktopAddon = _resolveComponent("VirtualDesktopAddon")!
  const _component_CodeScanAddon = _resolveComponent("CodeScanAddon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_PayrollAddon = _resolveComponent("PayrollAddon")!
  const _component_ContractAddon = _resolveComponent("ContractAddon")!
  const _component_LineManagerAddon = _resolveComponent("LineManagerAddon")!
  const _component_TDMAddon = _resolveComponent("TDMAddon")!
  const _component_CodeClanFundAddon = _resolveComponent("CodeClanFundAddon")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_form, {
      ref: "mainForm",
      loading: _ctx.loading,
      disabled: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { flat: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_SelectField, {
                    rules: [_ctx.rules.required],
                    items: _ctx.currencies,
                    modelValue: _ctx.selectedCurrency,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCurrency) = $event)),
                      _ctx.updateCurrencies
                    ],
                    label: "Currency",
                    placeholder: "Currency"
                  }, null, 8, ["rules", "items", "modelValue", "onUpdate:modelValue"]),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_NumberField, {
                      rules: [_ctx.rules.required],
                      modelValue: _ctx.selectedTax,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTax) = $event)),
                        _ctx.updateTaxes
                      ],
                      min: "0",
                      label: "Tax"
                    }, null, 8, ["rules", "modelValue", "onUpdate:modelValue"])
                  ])
                ]),
                _createVNode(_component_v_expansion_panels, {
                  modelValue: _ctx.panelOpenState,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.panelOpenState) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.timeSheetProcessing.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createElementVNode("div", _hoisted_3, [
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_checkbox, {
                                  onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
                                  modelValue: _ctx.model.timeSheetProcessing.Enabled,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.timeSheetProcessing.Enabled) = $event)),
                                  label: "Timesheet Processing",
                                  "hide-details": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.model.timeSheetProcessing.Amount) + " P/H", 1),
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                                    _createTextVNode("mdi-information")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_TimeSheetProcessingAddon, {
                              timeSheetProcessing: _ctx.model.timeSheetProcessing
                            }, null, 8, ["timeSheetProcessing"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.virtualDesktop.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_checkbox, {
                                  onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
                                  modelValue: _ctx.model.virtualDesktop.Enabled,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.virtualDesktop.Enabled) = $event)),
                                  label: "Virtual Desktop",
                                  "hide-details": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.model.virtualDesktop.Amount) + " P/H", 1),
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                                    _createTextVNode("mdi-information")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_VirtualDesktopAddon, {
                              isAddon: true,
                              virtualDesktopSpec: _ctx.model.virtualDesktop,
                              diskValues: _ctx.diskValues,
                              cpuValues: _ctx.cpuValues,
                              ramValues: _ctx.ramValues
                            }, null, 8, ["virtualDesktopSpec", "diskValues", "cpuValues", "ramValues"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, {
                      "hide-actions": "",
                      disabled: !_ctx.model.virtualDesktop.Enabled
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.codeScan.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_checkbox, {
                                  onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"])),
                                  modelValue: _ctx.model.codeScan.Enabled,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.codeScan.Enabled) = $event)),
                                  label: "Code Scan",
                                  "hide-details": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.model.codeScan.Amount) + " P/H", 1),
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                                    _createTextVNode("mdi-information")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_CodeScanAddon, {
                              codeScan: _ctx.model.codeScan
                            }, null, 8, ["codeScan"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.payRoll.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_checkbox, {
                                  onClick: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop"])),
                                  modelValue: _ctx.model.payRoll.Enabled,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.payRoll.Enabled) = $event)),
                                  label: "Payroll",
                                  "hide-details": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", _hoisted_15, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.model.payRoll.PayrollAmount) + " P/H", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.model.payRoll.SalaryCurrency || _ctx.selectedCurrency) + " " + _toDisplayString(_ctx.model.payRoll.SalaryAmount) + " " + _toDisplayString(_ctx.model.payRoll.SalaryInterval == "Hourly" ? "P/H" : _ctx.model.payRoll.SalaryInterval == "Monthly" ? "P/M" : "P/H"), 1)
                                ]),
                                _createVNode(_component_v_dialog, { "max-width": "500" }, {
                                  activator: _withCtx(({ props }) => [
                                    _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                                        _createTextVNode("mdi-information")
                                      ])),
                                      _: 2
                                    }, 1040)
                                  ]),
                                  default: _withCtx(({ isActive }) => [
                                    _createVNode(_component_v_card, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card_text, null, {
                                          default: _withCtx(() => _cache[23] || (_cache[23] = [
                                            _createElementVNode("div", { class: "d-flex flex-column" }, [
                                              _createElementVNode("h5", null, "Benefits Management:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Administering employee benefits programs such as loans."),
                                              _createElementVNode("span", { class: "ml-2" }, "Ensuring employees are aware of and utilizing available benefits."),
                                              _createElementVNode("h5", null, "Calculating Salaries and Wages:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Calculate base salary."),
                                              _createElementVNode("span", { class: "ml-2" }, "Apply mandatory and voluntary deductions such as insurance, and loan repayments."),
                                              _createElementVNode("h5", null, "Processing Payroll Payments:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Transfer salaries electronically to employees' bank accounts."),
                                              _createElementVNode("h5", null, "Addressing Queries and Complaints:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Respond to employee queries and complaints regarding payroll."),
                                              _createElementVNode("span", { class: "ml-2" }, "Resolve issues and discrepancies related to salary payments."),
                                              _createElementVNode("h5", null, "Reporting:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Prepare monthly, quarterly, and annual payroll reports.")
                                            ], -1)
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_card_actions, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_spacer),
                                            _createVNode(_component_v_btn, {
                                              class: "primary_btn",
                                              text: "Close",
                                              onClick: ($event: any) => (isActive.value = false)
                                            }, null, 8, ["onClick"])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_PayrollAddon, {
                              payRoll: _ctx.model.payRoll
                            }, null, 8, ["payRoll"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.contract.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createElementVNode("div", _hoisted_16, [
                              _createElementVNode("div", _hoisted_17, [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_checkbox, {
                                  onClick: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["stop"])),
                                  modelValue: _ctx.model.contract.Enabled,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.contract.Enabled) = $event)),
                                  label: "Contract",
                                  "hide-details": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.model.contract.Amount) + " P/H", 1),
                                _createVNode(_component_v_dialog, {
                                  "max-width": "500",
                                  scrollable: ""
                                }, {
                                  activator: _withCtx(({ props }) => [
                                    _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                                        _createTextVNode("mdi-information")
                                      ])),
                                      _: 2
                                    }, 1040)
                                  ]),
                                  default: _withCtx(({ isActive }) => [
                                    _createVNode(_component_v_card, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card_text, null, {
                                          default: _withCtx(() => _cache[25] || (_cache[25] = [
                                            _createElementVNode("div", { class: "d-flex flex-column" }, [
                                              _createElementVNode("h5", null, "Document and Credential Verification:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Collect and verify educational certificates, work experience documents, and identification proofs."),
                                              _createElementVNode("span", { class: "ml-2" }, "Ensure the authenticity of documents and the accuracy of the information provided."),
                                              _createElementVNode("span", { class: "ml-2" }, "Address, phone and email verification of TM."),
                                              _createElementVNode("h5", null, "Liaison with Relevant Authorities:"),
                                              _createElementVNode("span", { class: "ml-2" }, " Communicate with educational institutions, previous employers, and other relevant authorities to verify information and records. "),
                                              _createElementVNode("span", { class: "ml-2" }, "Perform background checks and criminal record investigations (if necessary)."),
                                              _createElementVNode("h5", null, "Contract Preparation and Drafting:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Prepare and draft employment contracts based on legal standards and organizational policies."),
                                              _createElementVNode("span", { class: "ml-2" }, "Consult with the legal team to ensure the contract complies with labor laws and regulations."),
                                              _createElementVNode("h5", null, "Negotiation with Candidates:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Conduct final negotiations with candidates regarding contract terms, benefits, and obligations."),
                                              _createElementVNode("span", { class: "ml-2" }, "Address candidates' questions and clarify any ambiguities regarding contract clauses."),
                                              _createElementVNode("h5", null, "Contract Signing:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Schedule contract signing meetings with the candidate and organizational representatives."),
                                              _createElementVNode("span", { class: "ml-2" }, "Collect necessary signatures and complete the contract signing process."),
                                              _createElementVNode("h5", null, "Onboarding and Orientation:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Provide initial training and familiarize the candidate with the organization's culture and policies."),
                                              _createElementVNode("span", { class: "ml-2" }, "Introduce the candidate to relevant teams and departments."),
                                              _createElementVNode("h5", null, "Record Keeping and Documentation:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Maintain and organize all documents and contracts related to employees."),
                                              _createElementVNode("span", { class: "ml-2" }, "Manage and update personnel files regularly."),
                                              _createElementVNode("h5", null, "Compliance Monitoring:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Ensure all legal and organizational regulations are followed during the verification and contract signing process."),
                                              _createElementVNode("span", { class: "ml-2" }, "Implement internal policies and procedures to ensure compliance with standards.")
                                            ], -1)
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_card_actions, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_spacer),
                                            _createVNode(_component_v_btn, {
                                              class: "primary_btn",
                                              text: "Close",
                                              onClick: ($event: any) => (isActive.value = false)
                                            }, null, 8, ["onClick"])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ContractAddon, {
                              contract: _ctx.model.contract
                            }, null, 8, ["contract"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.lineManager.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_checkbox, {
                                  onClick: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["stop"])),
                                  modelValue: _ctx.model.lineManager.Enabled,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.lineManager.Enabled) = $event)),
                                  label: "Line Manager",
                                  "hide-details": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.model.lineManager.Amount) + " P/H", 1),
                                _createVNode(_component_v_dialog, {
                                  "max-width": "500",
                                  scrollable: ""
                                }, {
                                  activator: _withCtx(({ props }) => [
                                    _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                                        _createTextVNode("mdi-information")
                                      ])),
                                      _: 2
                                    }, 1040)
                                  ]),
                                  default: _withCtx(({ isActive }) => [
                                    _createVNode(_component_v_card, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card_text, null, {
                                          default: _withCtx(() => _cache[27] || (_cache[27] = [
                                            _createElementVNode("div", { class: "d-flex flex-column" }, [
                                              _createElementVNode("h5", null, "Planning and Resource Allocation:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Allocating resources and tasks to team members based on project priorities and needs."),
                                              _createElementVNode("span", { class: "ml-2" }, "Planning and managing schedules to ensure goals and deadlines are met."),
                                              _createElementVNode("h5", null, "Communication and Coordination:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Establishing effective communication with other departments and managers to coordinate activities and projects."),
                                              _createElementVNode("span", { class: "ml-2" }, "Facilitating internal team communications and creating a space for discussion and problem-solving."),
                                              _createElementVNode("h5", null, "Performance Management:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Setting performance goals and standards for team members."),
                                              _createElementVNode("span", { class: "ml-2" }, "Monitoring the performance and productivity of employees and taking corrective actions as needed."),
                                              _createElementVNode("h5", null, "Problem Solving and Conflict Resolution:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Managing and resolving internal team problems and conflicts professionally and effectively."),
                                              _createElementVNode("span", { class: "ml-2" }, "Providing appropriate solutions to enhance collaboration and reduce tensions."),
                                              _createElementVNode("h5", null, "Employee Development and Training:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Identifying employees' training and development needs and providing training opportunities."),
                                              _createElementVNode("span", { class: "ml-2" }, "Assisting in the professional growth and development of employees through counseling and individual development planning."),
                                              _createElementVNode("h5", null, "Budget and Expense Management:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Monitoring the budget and expenses related to the team and projects."),
                                              _createElementVNode("span", { class: "ml-2" }, "Ensuring optimal use of financial resources and achievement of financial goals."),
                                              _createElementVNode("h5", null, "Evaluation and Reporting:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Preparing periodic reports on team performance and project progress."),
                                              _createElementVNode("span", { class: "ml-2" }, "Presenting reports and findings to senior managers and stakeholders."),
                                              _createElementVNode("h5", null, "Ensuring Compliance with Laws and Regulations:"),
                                              _createElementVNode("span", { class: "ml-2" }, "Ensuring compliance with organizational and governmental laws and regulations by the team."),
                                              _createElementVNode("span", { class: "ml-2" }, "Monitoring the implementation of organizational policies and processes within the team.")
                                            ], -1)
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_card_actions, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_spacer),
                                            _createVNode(_component_v_btn, {
                                              class: "primary_btn",
                                              text: "Close",
                                              onClick: ($event: any) => (isActive.value = false)
                                            }, null, 8, ["onClick"])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_LineManagerAddon, {
                              lineManagerAddon: _ctx.model.lineManager,
                              lineManagers: _ctx.lineManagers
                            }, null, 8, ["lineManagerAddon", "lineManagers"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.tdm.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("div", _hoisted_23, [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_checkbox, {
                                  onClick: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["stop"])),
                                  modelValue: _ctx.model.tdm.Enabled,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.tdm.Enabled) = $event)),
                                  label: "Talent Development Manager",
                                  "hide-details": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_24, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.model.tdm.Amount) + " P/H", 1),
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                                    _createTextVNode("mdi-information")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_TDMAddon, {
                              tdmModel: _ctx.model.tdm,
                              tdms: _ctx.tdms
                            }, null, 8, ["tdmModel", "tdms"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.codeClanFund.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_checkbox, {
                                  onClick: _cache[16] || (_cache[16] = _withModifiers(() => {}, ["stop"])),
                                  modelValue: _ctx.model.codeClanFund.Enabled,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.codeClanFund.Enabled) = $event)),
                                  label: "Code Clan Fund",
                                  "hide-details": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_27, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.model.codeClanFund.Amount) + " P/H", 1),
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                                    _createTextVNode("mdi-information")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_CodeClanFundAddon, {
                              codeClanFund: _ctx.model.codeClanFund
                            }, null, 8, ["codeClanFund"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", null, [
                    _cache[30] || (_cache[30] = _createTextVNode(" Total: ")),
                    _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.getTotalAmount) + " P/H", 1)
                  ]),
                  (_ctx.model.payRoll.Enabled && (_ctx.selectedCurrency != _ctx.model.payRoll.SalaryCurrency || (_ctx.model.payRoll.SalaryInterval && _ctx.model.payRoll.SalaryInterval != 'Hourly')))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.model.payRoll.SalaryCurrency) + " " + _toDisplayString(_ctx.model.payRoll.SalaryAmount ?? 0) + " " + _toDisplayString(_ctx.model.payRoll.SalaryInterval == "Hourly" ? "P/H" : "P/M"), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loading", "disabled"]),
    _createElementVNode("div", _hoisted_30, [
      _createVNode(_component_v_btn, {
        class: "secondary_btn",
        onClick: _ctx.backStep,
        loading: _ctx.loading
      }, {
        default: _withCtx(() => _cache[31] || (_cache[31] = [
          _createTextVNode("Previous")
        ])),
        _: 1
      }, 8, ["onClick", "loading"]),
      _createVNode(_component_v_btn, {
        class: "primary_btn",
        onClick: _ctx.nextStep,
        loading: _ctx.loading
      }, {
        default: _withCtx(() => _cache[32] || (_cache[32] = [
          _createTextVNode("Save")
        ])),
        _: 1
      }, 8, ["onClick", "loading"])
    ])
  ]))
}