import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_RoadMapSelectSteps = _resolveComponent("RoadMapSelectSteps")!
  const _component_RoadMapSelectTalents = _resolveComponent("RoadMapSelectTalents")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "roadmap-form",
    disabled: _ctx.isLoading,
    loading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "form-inputs" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmMain",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        class: "text_field",
                        label: "Title",
                        placeholder: "Enter Title",
                        modelValue: _ctx.roadMapModel.Title,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.roadMapModel.Title) = $event)),
                        rules: [_ctx.rules.required]
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextAreaField, {
                        class: "pt-0",
                        modelValue: _ctx.roadMapModel.Description,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.roadMapModel.Description) = $event)),
                        label: "Description",
                        rows: "3",
                        clearable: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_RoadMapSelectSteps, {
                        onAddStepToRoadMap: _ctx.addStepToRoadMap,
                        onDeleteStepFromRoadMap: _ctx.deleteStepsFromRoadMap,
                        steps: _ctx.roadMapModel.Steps
                      }, null, 8, ["onAddStepToRoadMap", "onDeleteStepFromRoadMap", "steps"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_RoadMapSelectTalents, {
                  onAddTalentsToRoadMap: _ctx.addTalentsToRoadMap,
                  onDeleteTalentFromRoadMap: _ctx.deleteTalentFromRoadMap,
                  DeleteTalentFromRoadMap: "",
                  talents: _ctx.roadMapModel.Talents,
                  teamMembers: _ctx.teamMembersList
                }, null, 8, ["onAddTalentsToRoadMap", "onDeleteTalentFromRoadMap", "talents", "teamMembers"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            onClick: _ctx.cancel,
            class: "secondary_btn"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Cancel")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            onClick: _ctx.saveRoadMap,
            class: "primary_btn_v2"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Save")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["disabled", "loading"]))
}