import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/code-clan-logo.svg'
import _imports_1 from '@/assets/images/icon/management.svg'
import _imports_2 from '@/assets/images/icon/cost.svg'
import _imports_3 from '@/assets/images/icon/training.svg'
import _imports_4 from '@/assets/images/icon/planning.svg'
import _imports_5 from '@/assets/images/icon/productivity.svg'
import _imports_6 from '@/assets/images/icon/security.svg'
import _imports_7 from '@/assets/images/icon/quick-actions.svg'


const _hoisted_1 = { class: "header-menu-container" }
const _hoisted_2 = { class: "header-menu" }
const _hoisted_3 = { class: "header-menu-item" }
const _hoisted_4 = { class: "header-menu-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("img", {
      src: _imports_0,
      class: "header-menu-logo"
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isPortfolioManager || _ctx.isClient)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/client/management",
            class: "header-menu-item"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "header-menu-item-content" }, [
                _createElementVNode("img", { src: _imports_1 }),
                _createElementVNode("span", null, "Management")
              ], -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isPortfolioManager || _ctx.isClient)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: "/client/cost",
            class: "header-menu-item"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "header-menu-item-content" }, [
                _createElementVNode("img", { src: _imports_2 }),
                _createElementVNode("span", null, "Cost")
              ], -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_menu, {
        modelValue: _ctx.trainingMenu,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.trainingMenu) = $event)),
        location: "bottom",
        transition: "scale-transition"
      }, {
        activator: _withCtx(({ props }) => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _mergeProps({ class: "header-menu-item-content" }, props), _cache[4] || (_cache[4] = [
              _createElementVNode("img", { src: _imports_3 }, null, -1),
              _createElementVNode("span", null, "Training", -1)
            ]), 16)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                to: "/client/training/roadMap",
                title: "Training RoadMaps",
                "prepend-icon": "mdi-account-school",
                value: "RoadMap"
              }),
              _createVNode(_component_v_list_item, {
                to: "/client/training/courses",
                title: "Courses",
                "prepend-icon": "mdi-school",
                value: "Courses"
              }),
              _createVNode(_component_v_list_item, {
                to: "/client/training/assessment",
                title: "Assessments",
                "prepend-icon": "mdi-book-clock",
                value: "Assessment"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.isPortfolioManager || _ctx.isClient)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 2,
            to: "/client/planning",
            class: "header-menu-item"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "header-menu-item-content" }, [
                _createElementVNode("img", { src: _imports_4 }),
                _createElementVNode("span", null, "Planning")
              ], -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isProjectLead || _ctx.isPortfolioManager || _ctx.isClient)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 3,
            to: "/client/productivity",
            class: "header-menu-item"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("div", { class: "header-menu-item-content" }, [
                _createElementVNode("img", { src: _imports_5 }),
                _createElementVNode("span", null, "Productivity")
              ], -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: "",
        class: "header-menu-item"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createElementVNode("div", { class: "header-menu-item-content" }, [
            _createElementVNode("img", { src: _imports_6 }),
            _createElementVNode("span", null, "Security")
          ], -1)
        ])),
        _: 1
      }),
      (_ctx.userIsClient || _ctx.userIsPortfolioManager)
        ? (_openBlock(), _createBlock(_component_v_menu, {
            key: 4,
            modelValue: _ctx.resourceMenu,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.resourceMenu) = $event)),
            location: "bottom",
            transition: "scale-transition"
          }, {
            activator: _withCtx(({ props }) => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _mergeProps({ class: "header-menu-item-content" }, props), _cache[8] || (_cache[8] = [
                  _createElementVNode("img", { src: _imports_7 }, null, -1),
                  _createElementVNode("span", null, "Resource", -1)
                ]), 16)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item, { to: "/client/virtualDesktop" }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Smart Workbench")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_list_item, { onClick: _ctx.GoToProject }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("Project")
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_list_item, { to: "/client/projectLead" }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("Project Lead")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_list_item, { to: "/client/portfolioManager" }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("Portfolio Manager")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}