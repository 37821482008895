import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-employee-box" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_CVTemplate1 = _resolveComponent("CVTemplate1")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.employee.PhotoUrl,
      class: "employee-avatar"
    }, null, 8, _hoisted_2),
    _createElementVNode("span", null, _toDisplayString(_ctx.capitalize(_ctx.employee.FirstName)) + " " + _toDisplayString(_ctx.capitalize(_ctx.employee.LastName)), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.getTitle(_ctx.employee.JobTitle)), 1),
    _createVNode(_component_v_btn, {
      onClick: _ctx.showTMDetails,
      text: "View Details"
    }, null, 8, ["onClick"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showDetails,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDetails) = $event)),
      class: "custom-modal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CVTemplate1, {
            employeeId: _ctx.employee?.Id ?? '',
            skills: _ctx.skills,
            details: _ctx.details,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDetails = false))
          }, null, 8, ["employeeId", "skills", "details"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}