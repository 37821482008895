<template>
  <div>
    <RoadMapFilter @doSearch="handleSearchFilter"></RoadMapFilter>
    <div class="mt-5">
      <div v-if="isMobile">
        <v-data-table :items="roadMaps" :headers="[]" item-value="name" :loading="isLoading">
          <template #item="{ item }">
            <div class="mobile-cell">
              <v-row>
                <v-col cols="10">
                  {{ item.Title }}
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" class="button">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                      <v-list-item v-for="actionButton in actionList" :key="actionButton">
                        <v-list-item-title>
                          <v-btn @click="doAction(actionButton, item)">
                            {{ actionButton == "Status" ? (item.Status == "Active" ? "Inactive" : "Active") : actionButton }}
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Talents</v-col>
                <v-col cols="6">{{ item.Talents.length }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Steps</v-col>
                <v-col cols="6">{{ getSteps(item) }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Info</v-col>
                <v-col cols="6"><v-icon class="button" @click="showRoadMapDetails(item.Id)">mdi-information</v-icon></v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Status</v-col>
                <v-col cols="6">{{ item.Status }}</v-col>
              </v-row>
            </div>
          </template>
        </v-data-table>
      </div>
      <v-data-table v-else :items="roadMaps" :headers="tableHeaders" item-value="name" :loading="isLoading">
        <template v-slot:item.Talents="{ item }">
          {{ item.Talents.length }}
        </template>
        <template v-slot:item.Steps="{ item }">{{ getSteps(item) }}</template>
        <template v-slot:item.Info="{ item }">
          <v-icon class="button" @click="showRoadMapDetails(item.Id)">mdi-information</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" class="button">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-list-item v-for="actionButton in actionList" :key="actionButton">
                <v-list-item-title>
                  <v-btn @click="doAction(actionButton, item)">
                    {{ actionButton == "Status" ? (item.Status == "Active" ? "Inactive" : "Active") : actionButton }}
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="showRoadMapDetailsDialog" fullscreen :scrim="false">
      <RoadMapDetails v-if="selectedRoadMapToShowDetails" :roadMapId="selectedRoadMapToShowDetails" @CloseModal="closeShowDetailsDialog"></RoadMapDetails>
    </v-dialog>
    <v-dialog v-model="showDeleteDialog" max-width="500px" persistent>
      <v-card :isLoading="isLoading">
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-btn class="secondary_btn" @click="cancelDeletePopup">Cancel</v-btn>
          <v-btn class="primary_btn" @click="deleteRoadMapItem">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUpdateStatusDialog" max-width="600px" persistent>
      <v-card :isLoading="isLoading">
        <v-card-title class="text-h6">Are you sure you want to change the status of this item?</v-card-title>
        <v-card-actions>
          <v-btn class="secondary_btn" @click="cancelStatusPopup">Cancel</v-btn>
          <v-btn class="primary_btn" @click="updateRoadMapStatus">Change</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600px" v-model="showAddTalentDialog">
      <SelectTalentsPopup
        :talents="talents"
        :teamMembers="teamMembersList"
        @addTalentsToRoadMap="addTalentsToRoadMap"
        @cancelSelection="cancelAddTalentDialog"></SelectTalentsPopup>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue"
  import RoadMapDetails from "./RoadMapDetails.vue"
  import RoadMapFilter from "./RoadMapFilter.vue"
  import RoadMapModel from "shared-components/src/models/RoadMapModel"
  import RoadMapService from "@/services/RoadMapService"
  import { REMOVE_SNAKBAR, SET_SNAKBAR_MODEL } from "../../../store/types"
  import { mapMutations } from "vuex"
  import { Profile } from "shared-components/src/definitions/config"
  import FindRoadMapsRequest from "shared-components/src/models/requests/FindRoadMapsRequest"
  import { TeammemberWithSummaryDetails } from "shared-components/src/models/Teammember"
  import TeammemberService from "@/services/TeammemberService"
  import SelectTalentsPopup from "./SelectTalentsPopup.vue"
  import { RoadMapTalentModel } from "shared-components/src/models/RoadMapModel"
  import store from "@/store"

  export default defineComponent({
    components: {
      RoadMapDetails,
      RoadMapFilter,
      SelectTalentsPopup,
    },
    data() {
      return {
        talents: [] as RoadMapTalentModel[],
        isMobile: false,
        showRoadMapDetailsDialog: false,
        stepsCount: "",
        talentsCount: "",
        trainingRoadmapName: "",
        TrainingDialogVisible: false,
        actionList: ["Delete", "Edit", "Add Talents", "Status"],
        roadMaps: [] as RoadMapModel[],
        isLoading: false,
        showDeleteDialog: false,
        selectedRoadMapIdToDelete: null,
        selectedRoadMapIdToAddTalent: null,
        showUpdateStatusDialog: false,
        showAddTalentDialog: false,
        selectedRoadMapToUpdateStatus: null as RoadMapModel | null,
        teamMembersSelected: [] as string[],
        selectedRoadMapToShowDetails: null as string | null,
        tableHeaders: [
          { title: "Name", key: "Title" },
          { title: "Talents", key: "Talents" },
          { title: "Steps", key: "Steps" },
          { title: "Info", key: "Info" },
          { title: "Status", key: "Status" },
          { title: "Actions", key: "actions" },
        ],
        searchQuery: {} as FindRoadMapsRequest,
        teamMembersList: [] as TeammemberWithSummaryDetails[],
      }
    },
    async mounted() {
      await this.loadRoadMaps()
      await this.loadTeammembers()
      this.isMobile = window.innerWidth <= 600
      window.addEventListener("resize", this.handleResize)
    },
    methods: {
      ...mapMutations([SET_SNAKBAR_MODEL, REMOVE_SNAKBAR]),
      async loadTeammembers() {
        this.teamMembersList = await TeammemberService.getListWithSummaryDetails()
      },
      handleSearchFilter(filterValues: { text: any }) {
        this.searchQuery.Text = filterValues.text
      },
      async loadRoadMaps() {
        this.isLoading = true
        this.roadMaps = await RoadMapService.GetList()
        this.isLoading = false
      },
      cancelDeletePopup() {
        this.selectedRoadMapIdToDelete = null
        this.showDeleteDialog = false
      },
      cancelAddTalentDialog() {
        this.teamMembersSelected = []
        this.selectedRoadMapIdToAddTalent = null
        this.showAddTalentDialog = false
      },
      async addTalentsToRoadMap(selectedTalents: any) {
        if (this.selectedRoadMapIdToAddTalent != null) {
          this.$emit("addTalentsToRoadMap", selectedTalents)
          this.teamMembersSelected = selectedTalents.map((talent: any) => talent.Id)
          try {
            this.isLoading = true
            await RoadMapService.AddTalents(this.selectedRoadMapIdToAddTalent, this.teamMembersSelected)
            const index = this.roadMaps.findIndex((item: any) => item.Id == this.selectedRoadMapIdToAddTalent)
            if (index != -1) {
              this.roadMaps[index].Talents = this.roadMaps[index].Talents.concat(
                this.teamMembersSelected.map((item: any) => {
                  return { Id: item, FullName: "" }
                })
              )
            }
            store.dispatch("showSuccessMessage", "Talents are added to Roadmap Successfully")
          } catch (error) {
            store.dispatch("showErrorMessage", "There is an Issue on Adding the talents to Roadmap")
          } finally {
            this.isLoading = false
          }
          await this.loadRoadMaps()

          this.cancelAddTalentDialog()
        }
      },
      async deleteRoadMapItem() {
        if (this.selectedRoadMapIdToDelete != null) {
          try {
            this.isLoading = true

            await RoadMapService.RemoveRoadMap(this.selectedRoadMapIdToDelete)
            const index = this.roadMaps.findIndex((item: any) => item.Id == this.selectedRoadMapIdToDelete)
            if (index != -1) {
              this.roadMaps.splice(index, 1)
            }
            this.SET_SNAKBAR_MODEL({
              body: "Roadmap Removed Successfuly",
              status: "success",
              button: "ok",
              timeout: Profile.ErrorSetting.SnackbarTimeout,
              show: true,
              handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
            })
          } catch (error) {
            this.SET_SNAKBAR_MODEL({
              body: "There is an Issue on removing the Roadmap",
              status: "error",
              button: "ok",
              timeout: Profile.ErrorSetting.SnackbarTimeout,
              show: true,
              handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
            })
          } finally {
            this.isLoading = false
          }

          this.cancelDeletePopup()
        }
      },
      cancelStatusPopup() {
        this.selectedRoadMapToUpdateStatus = null
        this.showUpdateStatusDialog = false
      },
      async updateRoadMapStatus() {
        if (this.selectedRoadMapToUpdateStatus != null) {
          try {
            this.isLoading = true
            let status: "Active" | "Inactive"
            if (this.selectedRoadMapToUpdateStatus.Status == "Active") {
              status = "Inactive"
            } else {
              status = "Active"
            }
            await RoadMapService.UpdateRoadMapStatus(this.selectedRoadMapToUpdateStatus.Id, status)
            const index = this.roadMaps.findIndex((item: any) => item.Id == this.selectedRoadMapToUpdateStatus?.Id)
            if (index != -1) {
              this.roadMaps[index].Status = status
            }
            this.SET_SNAKBAR_MODEL({
              body: "Roadmap Status Updated Successfuly",
              status: "success",
              button: "ok",
              timeout: Profile.ErrorSetting.SnackbarTimeout,
              show: true,
              handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
            })
          } catch (error) {
            this.SET_SNAKBAR_MODEL({
              body: "There is an Issue on Upadting the Roadmap Status",
              status: "error",
              button: "ok",
              timeout: Profile.ErrorSetting.SnackbarTimeout,
              show: true,
              handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
            })
          } finally {
            this.isLoading = false
          }

          this.cancelStatusPopup()
        }
      },
      getSteps(roadMap: RoadMapModel) {
        return `${roadMap.Steps.filter((item: any) => item.Type == "Course").length} Courses - ${roadMap.Steps.filter((item: any) => item.Type == "Assessment").length} Assessments`
      },
      handleResize() {
        this.isMobile = window.innerWidth <= 600
      },
      showRoadMapDetails(Id: string) {
        this.selectedRoadMapToShowDetails = Id
        this.showRoadMapDetailsDialog = true
      },
      closeShowDetailsDialog() {
        this.showRoadMapDetailsDialog = false
      },
      closeRoadmap() {
        this.TrainingDialogVisible = false
      },
      doAction(action: any, item: any) {
        if (action === "Edit") {
          this.$router.push({
            name: "editRoadmap",
            params: { roadMapId: item.Id },
          })
        } else if (action === "Delete") {
          this.selectedRoadMapIdToDelete = item.Id
          this.showDeleteDialog = true
        } else if (action === "Status") {
          this.selectedRoadMapToUpdateStatus = item
          this.showUpdateStatusDialog = true
        } else if (action === "Add Talents") {
          this.selectedRoadMapIdToAddTalent = item.Id
          this.talents = item.Talents
          this.showAddTalentDialog = true
        }
      },
      setTrainingRoadmapName(trainingRoadmapName: any) {
        this.trainingRoadmapName = trainingRoadmapName
      },
      setTalents(selectedTeamMembersCount: any) {
        this.talentsCount = selectedTeamMembersCount
      },
      cancelRoadMap() {
        this.TrainingDialogVisible = false
      },
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.handleResize)
    },
    watch: {
      searchQuery: {
        handler(val) {
          this.isLoading = true
          this.roadMaps = []
          RoadMapService.GetList(this.searchQuery).then((res: any) => {
            if (res.length <= 0) {
            }
            this.roadMaps = res
            this.isLoading = false
          })
        },
        deep: true,
      },
    },
  })
</script>

<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/colors.scss";

  .mobile-cell {
    padding: 20px;
    border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  }

  .color {
    background-color: $racecar;
  }
</style>
